import { QIcon_question_selected } from '@repo/icons/index'
import { CoursekeyquestionsReordering } from './LessonQuestionAnswerItem'

interface QButtonProps {
  number: string | number
  checked: CoursekeyquestionsReordering[]
  setActiveQuestionId: (value: number | string | null) => void
  activeQuestionId: string | number | null
  onChangeTab?: () => void
}

const LessonQuestionQButton = ({
  number,
  checked,
  setActiveQuestionId,
  activeQuestionId,
  onChangeTab,
}: QButtonProps) => {
  const isActive = activeQuestionId == number
  const selected = checked

  return (
    <>
      <button
        className={`Body2-14Bd relative w-[60px] rounded-[8px] border-[1.5px] px-[12px] py-[8px] ${
          isActive ? 'border-purple-500' : 'border-gray-300'
        } bg-${isActive ? 'purple-50' : 'white'} text-${isActive ? 'purple-500' : 'black'}`}
        onClick={() => {
          onChangeTab()
          setActiveQuestionId(number)
        }}
      >
        {selected && (
          <div className="absolute top-[-1.5px] right-[-1px]">
            <QIcon_question_selected className="" />
          </div>
        )}
        Q{number}
      </button>
    </>
  )
}

export default LessonQuestionQButton
