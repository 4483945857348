import {
  QIcon_chevron_double_left,
  QIcon_chevron_double_right,
  QIcon_chevron_left,
  QIcon_chevron_right,
} from '@repo/icons/index'
import classNames from 'classnames'

const Pagination = ({ postsPerPage, totalPosts, paginate, activeIndex, setActiveIndex, visiblePageNumber = 5 }) => {
  const pageNumbers = Array.from({ length: Math.ceil(totalPosts / postsPerPage) }, (_, i) => i + 1)
  // 현재 페이지를 중심으로 좌우로 페이지 번호를 계산합니다
  const startPage = Math.max(1, activeIndex + 1 - Math.floor(visiblePageNumber / 2))
  const endPage = Math.min(startPage + visiblePageNumber - 1, pageNumbers.length)

  // 보여줄 페이지 번호를 슬라이싱합니다
  const visiblePageNumbers = pageNumbers.slice(startPage - 1, endPage)

  const handlePrevButton = () => {
    setActiveIndex((prev) => prev - 1)
  }

  const handleNextButton = () => {
    setActiveIndex((prev) => prev + 1)
  }

  const disableLeftCursor = activeIndex + 1 === 1
  const disableRightCursor = activeIndex + 1 === pageNumbers[pageNumbers.length - 1]

  return (
    <div className="mt-4 flex justify-center">
      <nav className="flex">
        <ul className="flex space-x-4">
          {pageNumbers.length > 5 && (
            <>
              <li key={'very-first'} className="m-1 flex h-[30px] w-[30px] items-center rounded-[5px] border-[1px] p-1">
                <button
                  onClick={() => paginate(1)}
                  className={classNames(
                    'focus:shadow-outline rounded px-4 py-2 text-black focus:outline-none',
                    { 'text-gray-300': disableLeftCursor },
                    { 'hover:text-purple-500': !disableLeftCursor }
                  )}
                  disabled={disableLeftCursor}
                >
                  <QIcon_chevron_double_left className="h-[16px] w-[16px]" />
                </button>
              </li>
              <li key={'prev'} className="m-1 flex h-[30px] w-[30px] items-center rounded-[5px] border-[1px] p-1">
                <button
                  onClick={() => handlePrevButton()}
                  className={classNames(
                    'focus:shadow-outline rounded px-4 py-2 text-black focus:outline-none',
                    { 'text-gray-300': disableLeftCursor },
                    { 'hover:text-purple-500': !disableLeftCursor }
                  )}
                  disabled={disableLeftCursor}
                >
                  <QIcon_chevron_left className="h-[16px] w-[16px]" />
                </button>
              </li>
            </>
          )}
          {visiblePageNumbers.map((number) => (
            <li
              key={number}
              className={`m-1 flex h-[30px] w-[30px] items-center justify-center rounded-[5px] border-[1px] p-1 ${
                activeIndex + 1 === number ? 'border-purple-500' : ''
              }`}
            >
              <button
                onClick={() => paginate(number)}
                className={`focus:shadow-outline rounded px-4 py-2 text-black hover:text-purple-500 focus:outline-none ${
                  activeIndex + 1 === number ? 'text-purple-500' : ''
                }`}
              >
                {number}
              </button>
            </li>
          ))}
          {pageNumbers.length > 5 && (
            <>
              <li key={'prev-last'} className="m-1 flex h-[30px] w-[30px] items-center rounded-[5px] border-[1px] p-1">
                <button
                  onClick={() => handleNextButton()}
                  className={classNames(
                    'focus:shadow-outline rounded px-4 py-2 text-black focus:outline-none',
                    { 'text-gray-300': disableRightCursor },
                    { 'hover:text-purple-500': !disableRightCursor }
                  )}
                  disabled={disableRightCursor}
                >
                  <QIcon_chevron_right className="h-[16px] w-[16px]" />
                </button>
              </li>
              <li key={'very-last'} className="m-1 flex h-[30px] w-[30px] items-center rounded-[5px] border-[1px] p-1">
                <button
                  onClick={() => paginate(pageNumbers[pageNumbers.length - 1])}
                  className={classNames(
                    'focus:shadow-outline rounded px-4 py-2 text-black focus:outline-none',
                    { 'text-gray-300': disableRightCursor },
                    { 'hover:text-purple-500': !disableRightCursor }
                  )}
                  disabled={disableRightCursor}
                >
                  <QIcon_chevron_double_right className="h-[16px] w-[16px]" />
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  )
}

export default Pagination
