import { BUTTON_SIZE, BUTTON_TYPE, DSButton } from '@repo/design-system/index'
import { QIcon_close_default } from '@repo/icons/index'
import { useEffect, useState } from 'react'
import Speech from 'speak-tts'
import useTranslation from '../../modules/i18n/useTranslation'
import { ICON_PLAY_BUTTON_SMALL_PRIMARY } from '../../modules/svg/svg'
import { useSidePopup } from '../basic/popup/SidePopup'

interface ExpressionBodyProps {
  expressionData: any[]
  currentHash: any
  onClose?: () => void
}

const ExpressionBody = ({ expressionData, currentHash, onClose }: ExpressionBodyProps) => {
  console.log(expressionData, 'expressionData')
  console.log(currentHash, 'currentHash')

  const { t, isLocaleKO } = useTranslation()
  const sidePopup = useSidePopup()
  const [activeIndex, setActiveIndex] = useState(currentHash - 1)

  const handleSetVoice = () => {
    if ('speechSynthesis' in window) {
      const browserVoices = window.speechSynthesis.getVoices()
      /**
       * GOOGLE_US_EN_VOICE : Chrome only
       */
      const GOOGLE_US_EN_VOICE = 'Google US English'
      if (browserVoices.findIndex((voice) => voice.name === GOOGLE_US_EN_VOICE) >= 0) {
        ;(speech as any).setVoice(GOOGLE_US_EN_VOICE)
      } else {
        if ('speechSynthesis' in window && 'SpeechSynthesisUtterance' in window) {
          /**
           * For Other Browsers
           * 브라우저/OS마다 지원하는 voice가 다르고, en-US voice 중에서도 특이한 목소리가 있을 수 있기 때문에
           * 'Samantha' voice를 우선으로 사용한다.(Safari, firefox 지원됨)
           * Samantha voice가 없을 경우, 첫번째 en-US voice를 사용한다.
           * voice 청취 test https://jankapunkt.github.io/easy-speech
           */
          try {
            ;(speech as any).setVoice('Samantha')
          } catch (error) {
            const filterUniqueNames = (arr) => {
              // browservoices 중에서 lang === en-US 이고, 동일한 name이 존재하지 않는 첫 voice를 사용한다.
              // (동일한 name이 존재하는 경우, en아닌 다른 언어의 voice를 사용할 수도 있기 때문)
              const nameCount = arr.reduce((acc, cur) => {
                acc[cur.name] = (acc[cur.name] || 0) + 1
                return acc
              }, {})
              const uniqueNames = Object.keys(nameCount).filter((name) => nameCount[name] === 1)
              return arr.filter((obj) => uniqueNames.includes(obj.name))
            }
            const uniqueEnVoiceList = filterUniqueNames(browserVoices).filter((voice) => voice.lang === 'en-US')
            const availableVoiceName = uniqueEnVoiceList[0] ?? browserVoices[0]
            availableVoiceName?.name && (speech as any).setVoice(availableVoiceName?.name)
          }
        }
      }
    }
  }

  const initSpeechTTS = async () => {
    speech = new Speech()
    await speech.init({
      volume: 1,
      lang: 'en-US',
      rate: 1,
      pitch: 1,
      voice: 'Google US English',
      splitSentences: true,
      listeners: {
        onvoiceschanged: (voices) => {
          console.log('Event voiceschanged', voices)
        },
      },
    })
    handleSetVoice()
  }

  let speech: any = null

  const speak = (sample) => {
    let start_time
    ;(speech as any)
      ?.speak({
        text: sample.text,
        voice: 'Google US English',
        listeners: {
          onstart: () => {
            start_time = Date.now()
          },
        },
      })
      .then(() => {
        console.log('Success !')
      })
      .catch((e) => {
        console.error('An error occurred :', e)
      })
  }
  console.log(activeIndex, 'activeIndex')
  useEffect(() => {
    initSpeechTTS()
  }, [activeIndex])

  const handleListen = (e, sample) => {
    speak(sample)
  }

  const handlePrevButton = () => {
    setActiveIndex((prev) => prev - 1)
  }

  const handleNextButton = () => {
    setActiveIndex((prev) => prev + 1)
  }
  return (
    <div className="flex h-full flex-col justify-between">
      <div className="">
        <div className="flex flex-row justify-between p-[20px]">
          <div className="flex flex-row">
            <div className="Title1-20Bd">Expressions</div>
            <div className="Title1-20Md ml-[8px] flex flex-row text-gray-500">
              <div className="">{activeIndex + 1}/</div>
              <div className="">{expressionData?.length}</div>
            </div>
          </div>
          {onClose && <QIcon_close_default className="cursor-pointer" onClick={() => onClose()} />}
        </div>
        <div className="Headline2-24Bd py-[16px] px-[20px] text-purple-400">
          <div className="">{expressionData[activeIndex]?.expression}</div>
          {isLocaleKO ? (
            <>
              <div className="Body1-16Md my-[4px] text-black">{expressionData[activeIndex]?.meaning?.ko}</div>
              <div className="Body1-16Md text-black">{expressionData[activeIndex]?.meaning?.en}</div>
            </>
          ) : (
            <>
              <div className="Body1-16Md text-black">{expressionData[activeIndex]?.meaning?.en}</div>
            </>
          )}
        </div>
        <div className="">
          {expressionData[activeIndex]?.examples?.map((example, j) => (
            <div key={`example-${j}`} className="py-[16px] px-[20px]">
              <div className="flex items-center font-medium">
                <div className="Body1-16Bd mr-[4px]">Example</div>
                <div className="mr-[4px]">{j + 1}</div>
                <button onClick={(e) => handleListen(e, example)}>
                  <img src={ICON_PLAY_BUTTON_SMALL_PRIMARY} alt="play" className="h-[24px] w-[24px]" />
                </button>
              </div>
              <div key={j} className="Body1-16Md">
                <p className="my-[4px]">{example.text}</p>
                {isLocaleKO && <p className="mb-0">{example.meaning}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row gap-[8px] px-[20px] pb-[20px]">
        <DSButton
          type={BUTTON_TYPE.SOLID}
          size={BUTTON_SIZE.MD}
          onClick={handlePrevButton}
          disabled={activeIndex === 0}
        >
          <DSButton.Label>Back</DSButton.Label>
        </DSButton>
        <DSButton
          type={BUTTON_TYPE.OUTLINE}
          size={BUTTON_SIZE.MD}
          onClick={handleNextButton}
          disabled={activeIndex === expressionData?.length - 1}
        >
          <DSButton.Label>Next</DSButton.Label>
        </DSButton>
      </div>
    </div>
  )
}

export default ExpressionBody
