// @ts-strict-ignore
import { PortableText } from '@portabletext/react'
import { DSBottomSheet } from '@repo/design-system/index'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import groq from 'groq'
import React, { useContext, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import useIsMobile from '../../modules/hooks/common/useIsMobile'
import client from '../../modules/sanityClient'
import { TEXT_SIZE, TextContext } from '../../pages/[lang]/tutor/portal/lessons/lesson-materials/[materialId]'
import { useSidePopup } from '../basic/popup/SidePopup'
import LoadingSpinner from '../common/LoadingSpinner'
import { ptComponents, updateMarksToBreak } from '../sanity/util'
import ExpressionBody from './ExpressionBody'
import FreeTalkingInsightBody from './FreeTalkingInsightBody'
import MaterialInsightTitleSection from './MaterialInsightTitleSection'

const MaterialTextBody = ({ insightData, materialData, isLoading }) => {
  const { showKorean, textSize } = useContext(TextContext)
  return (
    <div className="mt-[28px] pb-[120px]">
      <div className="leading-7 md:leading-6">
        {/* HTML parser text */}
        {isLoading ? (
          <div>
            <LoadingSpinner show primary />
          </div>
        ) : insightData && insightData.paragraphList ? (
          insightData?.paragraphList.map((item, i) => {
            const contentEn = updateMarksToBreak(item.paragraph_en.content)
            const contentKo = updateMarksToBreak(item.paragraph_ko.content)
            const isNoneText = (content) =>
              content && content.length === 1 && content[0].children && content[0].children[0].text === ''
            return (
              <div
                key={i}
                className={classNames(
                  textSize === TEXT_SIZE.SMALLER && 'Body2-14Md sm:Title2-18Md',
                  textSize === TEXT_SIZE.DEFAULT && 'Body1-16Md sm:Title1-20Md',
                  textSize === TEXT_SIZE.BIGGER && 'Headline1-28Bd sm:Headline3-22Md',
                  'new-theme lists-class mb-[16px] flex flex-col break-keep'
                )}
              >
                <PortableText value={contentEn} components={ptComponents} />
                {showKorean && !isNoneText(contentKo) && (
                  <div
                    className={classNames(
                      textSize === TEXT_SIZE.SMALLER && 'Body2-14Md sm:Body2-14Md',
                      textSize === TEXT_SIZE.DEFAULT && 'Body1-16Md sm:Body1-16Md',
                      textSize === TEXT_SIZE.BIGGER && 'Title2-18Md sm:Title2-18Md',
                      'lists-class = mt-[16px] break-keep rounded-[4px] bg-gray-50 p-[16px]  text-gray-700'
                    )}
                  >
                    <PortableText value={contentKo} components={ptComponents} />
                  </div>
                )}
              </div>
            )
          })
        ) : (
          <div
            style={{
              overflowX: 'hidden',
              wordBreak: 'keep-all',
            }}
            className={classNames(
              textSize === TEXT_SIZE.SMALLER && 'Body2-14Md sm:Title2-18Md',
              textSize === TEXT_SIZE.DEFAULT && 'Body1-16Md sm:Title1-20Md',
              textSize === TEXT_SIZE.BIGGER && 'Headline1-28Bd sm:Headline3-22Md',
              'new-theme flex flex-col break-keep'
            )}
          >
            {!showKorean
              ? ReactHtmlParser(materialData.course.summary_eng.replace(/role="presentation"/g, ''))
              : ReactHtmlParser(materialData.course.summary_kor.replace(/role="presentation"/g, ''))}
          </div>
        )}

        <div className="Body1-16Md my-[40px] rounded-[10px] bg-gray-100 p-[16px] text-gray-500">
          *This material is designed for the exclusive use of Ringle students on the Ringle platform.
        </div>
      </div>
    </div>
  )
}

const fetchMaterial = async (materialId) => {
  const post = await client.fetch(groq`
    *[_type == "contentInsight" && slug.current == "${materialId}"][0]`)

  return post
}

const MaterialInsightBody = ({ materialData, materialId, isLesson = false, isModal = false }) => {
  const isMobile = useIsMobile()
  const sidePopup = useSidePopup()
  console.log(materialData, 'materialDatamaterialDatamaterialData')
  const [currentHash, setCurrentHash] = useState('')
  const [showBottomSheet, setShowBottomSheet] = useState(false)

  // 교재 인사이트 탭 데이터
  const { data: insightData, isLoading }: any = useQuery({
    queryKey: ['material', materialId],
    queryFn: () => fetchMaterial(materialId),
  })

  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = window.location.hash.replace(/[^0-9]/g, '') // only number
      console.log(currentHash, 'currentHash')
      setCurrentHash(currentHash)
    }

    window.addEventListener('hashchange', handleHashChange)

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  useEffect(() => {
    const targetHash = currentHash || window.location.hash.replace(/[^0-9]/g, '')

    if (isMobile) {
      if (targetHash) {
        setShowBottomSheet(true)
      } else {
        setShowBottomSheet(false)
      }
    } else {
      if (targetHash) {
        showSidePopup()
      }
    }
  }, [currentHash, isMobile])

  const showSidePopup = () => {
    sidePopup.show({
      id: 'expression-popup',
      overlayClose: true,
      border: true,
      bgWhite: true,
      maxW: 405,
      hFull: true,
      bodyClassNames: 'h-full',
      noTopSide: true,
      onClose: () => {
        const cleanURL = window.location.href.split('#')[0]
        window.history.replaceState(null, null, cleanURL)

        setCurrentHash('')
        sidePopup.close('expression-popup')
      },
      body: (
        <ExpressionBody
          expressionData={materialData?.course.expressions}
          currentHash={currentHash}
          onClose={handleClose}
        />
      ),
    })
  }

  const handleCloseBottomSheet = () => {
    if (window) window.location.hash = ''
  }

  const handleClose = () => {
    // 현재 URL에서 # 및 이후의 내용을 제거합니다.
    const cleanURL = window.location.href.split('#')[0]
    window.history.replaceState(null, null, cleanURL)

    sidePopup.close('expression-popup')
    setShowBottomSheet(false)
    setCurrentHash('')
  }

  return (
    <div className={`no-scrollbar relative`}>
      {materialData?.course?.free_talking ? (
        <FreeTalkingInsightBody data={materialData} />
      ) : (
        <>
          {/* 교재 상단 타이틀 영역 */}
          <MaterialInsightTitleSection materialData={materialData} isLesson={false} />
          {/* 교재 내용 본문 영역 */}
          <MaterialTextBody insightData={insightData} isLoading={isLoading} materialData={materialData} />
        </>
      )}
      {/* 모바일 : 핵심 표현 바텀 시트 영역 */}
      {showBottomSheet && (
        <DSBottomSheet
          enableScroll={true}
          id="ExpressionBottomSheet"
          isOpen={showBottomSheet}
          setIsOpen={setShowBottomSheet}
          closeButton={false}
          closeOnDim={true}
          closeOnDrag={true}
          onClose={handleCloseBottomSheet}
        >
          <ExpressionBody
            expressionData={materialData?.course.expressions}
            currentHash={currentHash}
            onClose={handleClose}
          />
        </DSBottomSheet>
      )}
    </div>
  )
}

const areEqualContent = (prev, next) => {
  return (
    prev.materialData.course.summary_kor === next.materialData.course.summary_kor &&
    prev.materialData.course.summary_eng === next.materialData.course.summary_eng &&
    prev.materialData.course.title_en === next.materialData.course.title_en &&
    prev.materialData.course.title_kr === next.materialData.course.title_kr &&
    prev.materialData.course.title === next.materialData.course.title
  )
}

export default React.memo(MaterialInsightBody, areEqualContent)
