// @ts-strict-ignore
import React from 'react'
import { ICON_CHEVRON_RIGHT_BOLD_BLACK } from '../../../modules/svg/svg'

interface TextCardHorizontalType {
  item: any
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const TextCardHorizontal: React.FC<TextCardHorizontalType> = ({ item, onClick }) => {
  return (
    <a target="_blank" href={item.url} onClick={onClick ? () => onClick(item) : null} rel="noreferrer">
      <div className="mb-[8px] flex justify-between rounded-lg border px-[16px] py-[24px] shadow">
        <div className="mr-[12px] truncate">{item.title}</div>
        <img src={ICON_CHEVRON_RIGHT_BOLD_BLACK} />
      </div>
    </a>
  )
}

export default TextCardHorizontal
