// @ts-strict-ignore
import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment } from 'react'
import { s_portal_webinar } from '../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../modules/i18n/useTranslation'
import { COLORS } from '../../../modules/vars'

type AudioPlayerPlaybackRatePropsType = {
  className?: string
  currentPlaybackRate: number
  setPlaybackRate: (rate: number) => void
}

const MaterialAudioPlaybackRate = ({ currentPlaybackRate, setPlaybackRate }: AudioPlayerPlaybackRatePropsType) => {
  const { t } = useTranslation()

  const handleChangePlaybackRate = (rate: number, closePopover: () => void) => {
    closePopover()
    setPlaybackRate(rate)
  }

  return (
    <Popover as="div" className="relative cursor-pointer self-center">
      {({ open }) => (
        <>
          <Popover.Button
            className={'lift Body2-14Md border-1 flex w-[40px] cursor-pointer justify-center rounded-[4px]'}
            style={{
              border: open && `1px solid ${COLORS.PURPLE500}`,
            }}
          >
            <div className={classNames('text-black')}>{`${currentPlaybackRate || '1'}x`}</div>
          </Popover.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel
              className="absolute z-20 flex flex-col gap-[8px] p-[8px]"
              style={{
                bottom: 'calc(100% + 8px)',
                backgroundColor: 'white',
                boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
                width: '120px',
                minWidth: '0',
                marginTop: '6px',
                boxSizing: 'border-box',
                borderRadius: '7px',
              }}
            >
              {({ close }) => (
                <>
                  {/* DROPDOWN MEN */}
                  {[0.8, 0.9, 1, 1.1, 1.2, 1.3].map((item, i) => {
                    return (
                      <div
                        onClick={() => handleChangePlaybackRate(item, close)}
                        key={i}
                        style={{
                          backgroundColor: currentPlaybackRate === item && COLORS.PURPLE50,
                        }}
                        className="Body2-14Md flex rounded-[4px] py-[11px] px-[12px] hover:bg-purple-50 hover:text-purple-500"
                      >
                        <div
                          style={{
                            color: currentPlaybackRate === item && COLORS.PURPLE500,
                          }}
                        >
                          {`${item === 1 ? '1.0' : item}x `}
                          {item === 1 && t(s_portal_webinar.normal)}
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default MaterialAudioPlaybackRate
