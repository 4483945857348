// @ts-strict-ignore
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import Speech from 'speak-tts'
import { s_lesson } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { ICON_PLAY_BUTTON_SMALL_PRIMARY } from '../../modules/svg/svg'
import EmptyPage from '../common/EmptyPage'

interface ExpressionPanelType {
  data: any
  onClickListen?: any //track
  paddingX?: number
  pbNone?: boolean
  isShort?: boolean
}
const ExpressionPanel: React.FC<ExpressionPanelType> = ({ data, onClickListen, pbNone = false, isShort = false }) => {
  const { t, isLocaleKO } = useTranslation()
  const [isMobile, setIsMobile] = useState(true)

  let speech: any = null
  const speak = (sample) => {
    let start_time
    ;(speech as any)
      ?.speak({
        text: sample.text,
        listeners: {
          onstart: () => {
            start_time = Date.now()
          },
          onend: () => {
            if (onClickListen) {
              const duration = Math.round((Date.now() - start_time) / 1000)
              console.log(duration)
              onClickListen(sample, duration)
            }
          },
        },
      })
      .then(() => {
        console.log('Success !')
      })
      .catch((e) => {
        console.error('An error occurred :', e)
      })
  }
  const handleListen = (e, sample) => {
    initSpeechTTS()
    speak(sample)
  }

  const handleSetVoice = () => {
    if ('speechSynthesis' in window) {
      const browserVoices = window.speechSynthesis.getVoices()
      /**
       * GOOGLE_US_EN_VOICE : Chrome only
       */
      const GOOGLE_US_EN_VOICE = 'Google US English'
      if (browserVoices.findIndex((voice) => voice.name === GOOGLE_US_EN_VOICE) >= 0) {
        ;(speech as any).setVoice(GOOGLE_US_EN_VOICE)
      } else {
        if ('speechSynthesis' in window && 'SpeechSynthesisUtterance' in window) {
          /**
           * For Other Browsers
           * 브라우저/OS마다 지원하는 voice가 다르고, en-US voice 중에서도 특이한 목소리가 있을 수 있기 때문에
           * 'Samantha' voice를 우선으로 사용한다.(Safari, firefox 지원됨)
           * Samantha voice가 없을 경우, 첫번째 en-US voice를 사용한다.
           * voice 청취 test https://jankapunkt.github.io/easy-speech
           */
          try {
            ;(speech as any).setVoice('Samantha')
          } catch (error) {
            const filterUniqueNames = (arr) => {
              // browservoices 중에서 lang === en-US 이고, 동일한 name이 존재하지 않는 첫 voice를 사용한다.
              // (동일한 name이 존재하는 경우, en아닌 다른 언어의 voice를 사용할 수도 있기 때문)
              const nameCount = arr.reduce((acc, cur) => {
                acc[cur.name] = (acc[cur.name] || 0) + 1
                return acc
              }, {})
              const uniqueNames = Object.keys(nameCount).filter((name) => nameCount[name] === 1)
              return arr.filter((obj) => uniqueNames.includes(obj.name))
            }
            const uniqueEnVoiceList = filterUniqueNames(browserVoices).filter((voice) => voice.lang === 'en-US')
            const availableVoiceName = uniqueEnVoiceList[0] ?? browserVoices[0]
            availableVoiceName?.name && (speech as any).setVoice(availableVoiceName?.name)
          }
        }
      }
    }
  }

  const initSpeechTTS = () => {
    speech = new Speech()
    speech.init({
      volume: 1,
      lang: 'en-US',
      rate: 1,
      pitch: 1,
      splitSentences: true,
      listeners: {
        onvoiceschanged: (voices) => {
          console.log('Event voiceschanged', voices)
        },
      },
    })
    handleSetVoice()
  }

  useEffect(() => {
    !isShort && initSpeechTTS()
  }, [isShort])

  useEffect(() => {
    if (window.screen.width < window.innerWidth) {
      setIsMobile(window.screen.width < 576)
    } else {
      setIsMobile(window.innerWidth < 576)
    }
  }, [])

  return (
    <div style={{ paddingBottom: pbNone ? '0px' : 'calc(15%)' }} className="no-scrollbar text-black sm:px-20 lg:px-0">
      <div className="">
        <>
          {data.expressions?.length > 0 ? (
            <>
              {data.expressions.map((item, i) => {
                if (isShort && i > 0) return //랜딩 컨텐츠에서 표현 하나만 보여줍니다.
                return (
                  <div
                    key={i}
                    className={classNames(
                      { 'h-[500px]': isMobile && isShort },
                      { 'overflow-hidden': isMobile && isShort },
                      { 'px-[20px]': isMobile },
                      { 'border-b': item?.examples?.length > 0 },
                      'mb-[32px]'
                    )}
                  >
                    <div className="text-h1 mb-[14px] text-[20px] font-bold text-purple-500">{item.expression}</div>
                    <div className="mb-[16px]">
                      {isLocaleKO && <div className="text-h3 mb-[8px] text-[16px] font-medium">{item.meaning.ko}</div>}
                      <div className="mb-[16px] border-b pb-[16px]">{item.meaning.en}</div>
                      {item.tip && isLocaleKO && (
                        <div className="mb-[16px] border-b">
                          <div className="mb-[8px] font-medium">Ringle Tip</div>
                          <div className="mb-[16px]">{item.tip}</div>
                        </div>
                      )}
                    </div>
                    {item.examples && item.examples.length > 0 && (
                      <div>
                        <div>
                          {item.examples.map((example, j) => (
                            <div key={`example-${j}`}>
                              <div className="mb-[8px] flex items-end font-medium">
                                <div className="mr-[4px]">{t(s_lesson.example)}</div>
                                <div className="mr-[8px]">{j + 1}</div>
                                <button onClick={(e) => handleListen(e, example)}>
                                  <img src={ICON_PLAY_BUTTON_SMALL_PRIMARY} />
                                </button>
                              </div>
                              <div key={j} className="mb-[16px]">
                                <p className="mb-8">{example.text}</p>
                                {isLocaleKO && <p className="mb-0">{example.meaning}</p>}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </>
          ) : (
            <EmptyPage title={t(s_lesson.does_not_provide_expressions)} />
          )}
        </>
      </div>
    </div>
  )
}
export default ExpressionPanel
