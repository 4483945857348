import { NextPageContext } from 'next'
import cookies from 'next-cookies'
import { useRouter } from 'next/router'
import { createContext, useState } from 'react'
import TutorMaterialDetailBody from '../../../../../../components/lessons/lesson-materials/TutorMaterialDetailBody'
import ContentNavBar from '../../../../../../components/menu/ContentNavBar'
import NewTutorLayoutContainer from '../../../../../../components/tutor/NewTutorLayoutContainer'
import { tutor_apis } from '../../../../../../modules/apis'
import { axiosGet } from '../../../../../../modules/axiosHelper'
import { useCurrentUser } from '../../../../../../modules/context/UserContext'
import LinkLocale from '../../../../../../modules/i18n/LinkLocale'
import { r_resource } from '../../../../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../../../../modules/i18n/useTranslation'
import urls from '../../../../../../modules/urls'
import { LOCATION } from '../../../../../../modules/vars'

export const TEXT_SIZE = {
  DEFAULT: 'default',
  BIGGER: 'bigger',
  SMALLER: 'smaller',
}

export const TextContext = createContext({
  showKorean: false,
  setShowKorean: null,
  textSize: TEXT_SIZE.DEFAULT,
  setTextSize: null,
})

// 한글 같이 보기 버튼에 대한 페이지 전역 처리
export function TextProvider({ children }) {
  const [showKorean, setShowKorean] = useState<boolean>(false)
  const [textSize, setTextSize] = useState<string>(TEXT_SIZE.DEFAULT)

  return (
    <TextContext.Provider value={{ showKorean, setShowKorean, textSize, setTextSize }}>{children}</TextContext.Provider>
  )
}

const MaterialDetail = ({ materialData }) => {
  console.log(materialData, ' materialData')
  const { t, isLocaleEN } = useTranslation()
  const { currentUser } = useCurrentUser()
  const router = useRouter()

  const handleExit = () => {
    router.back()
  }

  const materialTitle = materialData?.course.title_en ? materialData?.course.title_en : materialData?.course.title

  if (!currentUser) return <></>

  return (
    <NewTutorLayoutContainer noContainer>
      <ContentNavBar
        clx="hidden sm:flex"
        location={LOCATION.COURSE}
        onExitContent={handleExit}
        pageType={
          <LinkLocale href={urls.tutor.portal.resources.lessonMaterials.index}>{t(r_resource.title)}</LinkLocale>
        }
        description={materialTitle}
      />
      <TextProvider>
        <TutorMaterialDetailBody data={materialData} />
      </TextProvider>
    </NewTutorLayoutContainer>
  )
}

MaterialDetail.getInitialProps = async (context: NextPageContext) => {
  const { materialId } = context.query

  const { jwt_token } = cookies(context)
  const json = await axiosGet(tutor_apis.resources.courses.detail(materialId), { initialToken: jwt_token })

  return {
    materialData: json,
  }
}

export default MaterialDetail
