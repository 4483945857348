import { TAB_TYPE } from '@repo/design-system/index'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { tutor_apis } from '../../../../modules/apis'
import { axiosGet, axiosPost } from '../../../../modules/axiosHelper'
import useIsMobile from '../../../../modules/hooks/common/useIsMobile'
import { r_preparation, s_preparation } from '../../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../../modules/i18n/useTranslation'
import ResizableImage from '../../../common/ResizableImage'
import LessonQuestionAnswerItem, { CardTextarea } from './QuestionSelect/LessonQuestionAnswerItem'
import LessonQuestionMyAnswerHistory from './QuestionSelect/LessonQuestionMyAnswerHistory'
import LessonQuestionPeopleOpinions from './QuestionSelect/LessonQuestionPeopleOpinions'
import LessonQuestionQButton from './QuestionSelect/LessonQuestionQButton'
import { AnswerSubTab, getShareAnswer } from './QuestionSelect/LessonQuestionQuestionSelectPanel'

// 수업 준비 아닌 경우 - 답변 저장하기 버튼 클릭 시 - API 불러서 작성하고 있는 내용을 저장하고
//isEditable 을 false 로 설정해서 수정하기 상태로 갈 수 있도록
const handleSubmitAnswer = async ({ course_id, question_number, content, shareStatus, isEditable, setIsEditable }) => {
  // content 아무것도 없을 수 없음 => 저장하기 버튼 비활성화임
  const payload = {
    course_id: Number(course_id),
    question_number: question_number,
    content,
    share_status: shareStatus, // 1: me, 0:public, 2: tutor and me
  }
  const response = await axiosPost(tutor_apis.lessons.preparation.answerV5(), payload)

  if (response) {
    const responseQuestion = await axiosGet(tutor_apis.course.coursekeyquestions(course_id))
    if (responseQuestion) {
      setIsEditable(
        responseQuestion.coursekeyquestions.map((question) => ({
          ...question,
          currentAnswer: '', // 현재 보관 중인 answer
          currentAnswerStatus: 0, // 현재 보관 중인 share_status
          editable: true,
        }))
      )
    }
  }
}

export const fetchCourseData = async (courseId) => {
  const response = await axiosGet(tutor_apis.course.coursekeyquestions(courseId))
  if (response) {
    return response.coursekeyquestions
  }
}

interface IQuestionPanel {
  id: number
  materialData: any
  isClassroom?: boolean
}
const QuestionPanel = ({ id, materialData, isClassroom = false }: IQuestionPanel) => {
  const { t } = useTranslation()
  const isWebview = useIsMobile()
  const router = useRouter()
  const isMaterial = useMemo(() => router.asPath.includes('/lesson-materials/'), [router.asPath])

  const [questionTranslate, setQuestionTranslate] = useState<boolean>(false)
  const [myAnswers, setMyAnswers] = useState([])
  const [shareAnswers, setShareAnswers] = useState([])
  const [shareParticipant, setParticipantList] = useState([])
  const [answerSubTabId, setAnswerSubTab] = useState<string>('my_answer')
  const [isEditable, setIsEditable] = useState([])
  const [answer, setAnswer] = useState('')
  const [selectedShareState, setSelectedShareState] = useState<number>(0)
  const [activeQuestionId, setActiveQuestionId] = useState<number | string>('1')
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    const fetchAnswerData = async (courseId) => {
      try {
        const response = await axiosGet(tutor_apis.course.coursekeyquestions(courseId))
        if (response && response.coursekeyquestions) {
          setIsEditable(
            response.coursekeyquestions.map((question) => ({
              ...question,
              currentAnswer: '', // 현재 보관 중인 answer
              currentAnswerStatus: 0, // 현재 보관 중인 share_status
              editable: true,
            }))
          )
          setAnswer('')
          setSelectedShareState(0)
          setQuestions(response.coursekeyquestions)
        }
      } catch {
        console.log('fetch error')
      }
    }
    if (id) {
      fetchAnswerData(id)
    }
  }, [id, answerSubTabId, activeQuestionId])

  useEffect(() => {
    setAnswer(isEditable?.find((item, idx) => Number(item.question_number) == activeQuestionId)?.currentAnswer)
    setSelectedShareState(
      isEditable?.find((item, idx) => Number(item.question_number) == activeQuestionId)?.currentAnswerStatus
    )
    setMyAnswers(isEditable?.filter((item) => item.question_number == activeQuestionId)[0]?.answers?.my_answers)
    getShareAnswer(
      isEditable?.filter((item) => item.question_number == activeQuestionId)[0]?.id,
      setShareAnswers,
      setParticipantList
    )
  }, [activeQuestionId, isEditable, answerSubTabId])

  const optionList = [
    {
      key: 'my_answer',
      label: 'My answer',
      number: myAnswers?.length,
    },
    {
      key: 'other_answer',
      label: 'All answers',
      number: shareAnswers?.length,
    },
  ]

  const onChangeTab = () => {
    console.log('change tab')

    const updatedIsEditable = isEditable.map((item) => {
      if (Number(item.question_number) == activeQuestionId) {
        return {
          ...item,
          currentAnswer: answer,
          currentAnswerStuatus: selectedShareState,
        }
      }
      return item
    })

    // 새 배열을 사용하여 상태를 업데이트합니다.
    setIsEditable(updatedIsEditable)
    setAnswer('')
    setSelectedShareState(0)
  }

  const handleOpenOpinion = (answers) => {
    console.log('handling open opinion')
  }

  const filteredQuestionList = isMaterial ? questions : materialData?.coursekeyquestions_reordering

  const sortByQuestionNumber = (arr) => {
    return arr.sort((a, b) => parseInt(a.question_number) - parseInt(b.question_number))
  }

  const sortedQuestionList = sortByQuestionNumber(filteredQuestionList)

  return (
    <div>
      <div className="mb-[24px] flex gap-x-8 overflow-x-auto sm:flex-wrap sm:gap-y-8">
        {sortedQuestionList?.map((item, index) => (
          <div
            key={`${index}_${item.id}`}
            onClick={(e) => {
              console.log(item.id)

              getShareAnswer(item.id, setShareAnswers, setParticipantList)
            }}
          >
            <LessonQuestionQButton
              key={`${index}_${item.id}`}
              number={item?.question_number}
              checked={item.student_choice}
              activeQuestionId={activeQuestionId}
              setActiveQuestionId={setActiveQuestionId}
              onChangeTab={onChangeTab}
            />
          </div>
        ))}
      </div>
      {/* 질문카드 */}
      <div className="mt-[4px]">
        <div key={`question-${activeQuestionId}`}>
          <div>
            <div>
              <div className="mb-[8px] flex"></div>
              <div className="text-h2 mb-[8px] font-bold">
                {isEditable.find((item) => item.question_number === activeQuestionId)?.content_main}
              </div>
              {questionTranslate && (
                <div className="mb-[16px] text-[16px] font-medium text-gray-500">
                  {isEditable?.find((item) => item.question_number == activeQuestionId)?.content_main_ko}
                </div>
              )}
              {isEditable.find((item) => item.question_number === activeQuestionId)?.img_url_1 &&
                isEditable.find((item) => item.question_number === activeQuestionId)?.img_url_1 !== '' && (
                  <ResizableImage
                    url={isEditable.find((item) => item.question_number === activeQuestionId)?.img_url_1}
                  />
                )}
            </div>
            <div style={{ height: 1 }} className="my-[20px] bg-gray-200"></div>
            <div>
              {!isMaterial && (
                <>
                  <div className="Body1-16Bd mb-[8px]">{t(r_preparation.student_response)}</div>
                  <CardTextarea
                    cardClass={'rounded bg-gray-50'}
                    disabled={true}
                    minRows={4}
                    defaultValue={
                      isMaterial
                        ? ''
                        : materialData?.coursekeyquestions_reordering[Number(activeQuestionId) - 1]?.student_answer
                            ?.content
                    }
                    placeholder={t(s_preparation.no_saved_answer)}
                    className={'bg-gray-50'}
                  />
                </>
              )}
            </div>
            <div className="Body1-16Bd mb-[8px] mt-[20px]">Write your opinion</div>
            <div>
              {/* 내 답변 */}
              <LessonQuestionAnswerItem
                answer={
                  isMaterial
                    ? answer
                    : materialData?.coursekeyquestions_reordering[Number(activeQuestionId) - 1]?.tutor_answer_txt
                }
                setAnswer={setAnswer}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                activeQuestionId={Number(activeQuestionId)}
                lessonId={id}
                handleSubmitAnswer={handleSubmitAnswer}
                onBlur={null}
                isReview={false}
                isLesson={false}
                isPast={() => {
                  return false
                }}
                selectedShareState={selectedShareState}
                setSelectedShareState={setSelectedShareState}
              />
            </div>
            {/** webview 일때는 회색 선이 없어야 함 */}
            {!isWebview && (
              <div style={{ height: 8 }} className="border-t-1 mt-[40px] border-gray-100 bg-gray-50"></div>
            )}
            {isWebview && <div className="h-[36px]"></div>}
          </div>
        </div>
      </div>
      {/** 답변 확인 */}
      <div>
        <AnswerSubTab
          optionList={optionList}
          activeKey={answerSubTabId}
          setActiveKey={setAnswerSubTab}
          type={TAB_TYPE.SCROLL}
        />
        {answerSubTabId === 'my_answer' ? (
          <LessonQuestionMyAnswerHistory
            isEditable={isEditable}
            myAnswers={myAnswers}
            setMyAnswers={setMyAnswers}
            id={id}
            activeQuestionId={activeQuestionId}
            isClassroom={isClassroom}
          />
        ) : (
          <LessonQuestionPeopleOpinions
            answers={shareAnswers}
            participantList={shareParticipant}
            handleOpenOpinion={handleOpenOpinion}
            tutorAnswerComponent={null}
            activeQuestionId={activeQuestionId}
            isClassroom={isClassroom}
          />
        )}
      </div>
    </div>
  )
}

export default QuestionPanel
