// @ts-strict-ignore
import { useToast } from '@repo/design-system/index'
import {
  QIcon_back_5sec,
  QIcon_forward_5sec,
  QIcon_minimize,
  QIcon_pause_single,
  QIcon_play_single,
  QIcon_repeat,
} from '@repo/icons/index'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useCurrentUser } from '../../../modules/context/UserContext'
import { s_content } from '../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../modules/i18n/useTranslation'
import { DATA_REF_KEY } from '../../../modules/ids/e2e/student'
import { COLORS } from '../../../modules/vars'
import { putAudioCurrentTime } from '../../../reducer/reviewReducer'
import { RootState } from '../../../reducer/rootReducer'
import MaterialAudioPlaybackRate from './MaterialAudioPlaybackRate'

type PlayerPropsType = {
  src: string
  onEnd?: any
  onPlay?: any
  onPause?: any
  onClickRange?: any
  playbacktime?: number
  isLanding?: boolean
  isMobile?: boolean
  onClickEndButton?: any
}

const MaterialAudioPlayer = forwardRef(
  (
    {
      src,
      onEnd,
      onPlay,
      onPause,
      onClickRange = null,
      playbacktime,
      isLanding,
      isMobile = false,
      onClickEndButton,
    }: PlayerPropsType,
    ref: any
  ) => {
    const dispatch = useDispatch()
    const { audioCurrentTime, isAudioAuto } = useSelector(
      (state: RootState) => ({
        audioCurrentTime: state.review.audioCurrentTime,
        isAudioAuto: state.review.audioAuto,
        lesson: state.review.lessonInfo,
      }),
      shallowEqual
    )
    const router = useRouter()
    const { currentUser } = useCurrentUser()
    const { toastInfo } = useToast()
    const typeQuery = router?.query?.type && router.query.type
    const mp3_playback_time = router?.query?.mp3_playback ? router.query.mp3_playback : 0
    const lesson_playback_time = router?.query?.lesson_playback ? router.query.lesson_playback : 0
    const video_playback_time = router?.query?.video_playback ? router.query.video_playback : 0
    const [rangeValue, setRangeValue] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [isLooping, setIsLooping] = useState(false)

    const [endTime, setEndTime] = useState(0)
    const [isPause, setIsPause] = useState<boolean>(true)
    const [isMinimize, setIsMinimize] = useState<boolean>(false)
    const aInterval = useRef(null)
    const rangeInputRef = useRef(null)
    const isChangingValue = useRef(false)
    const { t } = useTranslation()

    useEffect(() => {
      if (ref && ref?.current) {
        handleAudioLoad()
      }
    }, [ref])

    useEffect(() => {
      if (ref && ref?.current) {
        const audio = ref.current

        if (!isAudioAuto) {
          audio.currentTime = audioCurrentTime
        }
        setCurrentTime(audio.currentTime)
        if (endTime === 0) {
          handleAudioLoad()
        }
        if (!isChangingValue.current) {
          setRangeValue((1 / audio.duration) * audioCurrentTime)
        }
      }
    }, [ref, audioCurrentTime, mp3_playback_time, lesson_playback_time, video_playback_time, typeQuery])

    //슬라이더 위치 update
    useEffect(() => {
      if (ref && ref?.current) {
        const audio = ref.current

        if (audio?.duration) {
          if (!isChangingValue.current) {
            setRangeValue((1 / audio.duration) * audioCurrentTime)
          }
        }
      }
    }, [ref?.current?.duration])

    useEffect(() => {
      if (ref && ref?.current) {
        const audio = ref.current

        if (!currentUser && isLanding && audioCurrentTime > 60) {
          audio.pause()
          dispatch(putAudioCurrentTime(0, false))
          setCurrentTime(0)
          toastInfo(t(s_content.mobile_web.finished_preview))
        }
      }
    }, [ref, audioCurrentTime, currentTime, currentUser])

    useEffect(() => {
      return () => {
        if (aInterval.current) clearInterval(aInterval.current)
        dispatch(putAudioCurrentTime(0, false))
      }
    }, [])

    useEffect(() => {
      if (!isChangingValue.current) {
        if (typeQuery === 'recording') {
          dispatch(putAudioCurrentTime(lesson_playback_time, false))
        }
        //복습상세(예습내역)
        if (typeQuery === 'course') {
          dispatch(putAudioCurrentTime(playbacktime, false))
        }
        //예습상세(MP3듣기 클릭시)
        if (typeQuery === 'mp3') {
          dispatch(putAudioCurrentTime(playbacktime, false))
        }
        if (typeQuery === 'youtube') {
          dispatch(putAudioCurrentTime(0, false))
        }
        // 교재 상세(typeQuery 없음)
        if (typeQuery == undefined) {
          dispatch(putAudioCurrentTime(playbacktime, false))
          return
        } else {
          // dispatch(putAudioCurrentTime(0, false))
          return
        }
      }
    }, [])

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60)
        .toString()
        .padStart(2, '0')
      const seconds = Math.floor(time % 60)
        .toString()
        .padStart(2, '0')
      return `${minutes}:${seconds}`
    }

    const handleStartButtonClick = (e) => {
      e.stopPropagation()
      const audio = ref.current
      const rangeInput = rangeInputRef.current
      if (audio && rangeInput) {
        if (isPause) {
          audio.play()
          onPlay && onPlay()
          console.log('start')
          setIsPause(false)
          aInterval.current = setInterval(() => {
            dispatch(putAudioCurrentTime(audio.currentTime, true))
          }, 1000)
        } else {
          setIsPause(true)
          onPause && onPause()
          console.log('pause')
          audio.pause()
          if (aInterval.current) {
            clearInterval(aInterval.current)
          }
        }
      }
    }

    const handleRangeInputChange = (e, audio) => {
      if (audio) {
        setRangeValue(e.target.value)
        setCurrentTime(e.target.value * audio.duration)
        onClickRange && onClickRange(e.target.value * audio.duration)
      }
    }

    const handleRangeInputMouseUp = (e, audio) => {
      console.log(audio, isChangingValue.current)
      if (audio && isChangingValue.current) {
        dispatch(putAudioCurrentTime(e.target.value * audio.duration, false))
        isChangingValue.current = false
      }
    }

    const handleAudioLoad = () => {
      if (ref && ref.current?.duration) {
        setEndTime(ref.current.duration ?? 0)
      }
    }

    const handleAudioEnd = () => {
      if (ref.current) {
        const audio = ref.current
        setIsPause(true)
        audio.pause()
        // dispatch(putAudioCurrentTime(0, true))

        if (aInterval.current) {
          clearInterval(aInterval.current)
        }
        setCurrentTime(0)
        setRangeValue(0)
        onEnd && onEnd()
      }
    }

    const handleRangeInputMouseDown = () => {
      if (isChangingValue) {
        isChangingValue.current = true
      }
    }

    const handlePrevTimeClick = () => {
      const audio = ref.current
      if (audio) {
        dispatch(putAudioCurrentTime(audio.currentTime - 5, false))
      }
    }

    const handleNextTimeClick = () => {
      const audio = ref.current
      if (audio) {
        dispatch(putAudioCurrentTime(audio.currentTime + 5, false))
      }
    }

    const handlePlaybackRate = (rate: number) => {
      const audio = ref.current
      if (audio) {
        audio.playbackRate = rate

        if (isPause) {
          audio.play()
          onPlay && onPlay()
          console.log('start')
          setIsPause(false)
          aInterval.current = setInterval(() => {
            dispatch(putAudioCurrentTime(audio.currentTime, true))
          }, 1000)
        }
      }
    }

    const toggleLooping = () => {
      setIsLooping(!isLooping)
    }

    const ratio = (audioCurrentTime / ref?.current?.duration) * 100

    const handleEngButton = () => {
      onClickEndButton && onClickEndButton()
    }

    const handleMinimize = () => {
      setIsMinimize(true)
    }

    if (isMobile) {
      return (
        <div
          className={classNames(
            isMinimize
              ? 'bottom-[80px] right-[50%] w-[188px] translate-x-1/2 rounded-[50px] rounded-t-[50px] px-[16px] pb-[8px] pt-[8px]'
              : 'bottom-0 w-full',
            'NewAudioPlayer-container fixed z-[20] flex items-center rounded-t-[16px] border-gray-200 bg-white pb-[31px] pb-[12px] pt-[16px] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.15)] sm:pb-0'
          )}
        >
          <div className={classNames(isMinimize ? 'px-[0px]' : 'px-[20px]', 'NewAudioPlayer-block relative')}>
            {src && (
              <audio
                preload="auto"
                src={src}
                ref={ref}
                onLoadedData={handleAudioLoad}
                onEnded={handleAudioEnd}
                loop={isLooping}
              />
            )}
            <div
              onClick={() => setIsMinimize(false)}
              className={classNames(!isMinimize && 'hidden', 'flex flex-row items-center justify-between')}
            >
              {/* 현재 시간*/}
              <div className="w-[38px]">
                <div className="Body2-14Md">{formatTime(currentTime)}</div>
              </div>
              {/* 재생 버튼 */}
              <button
                data-ref={DATA_REF_KEY.btn_play}
                onClick={(e) => handleStartButtonClick(e)}
                className="focus:outline-none"
              >
                {isPause ? <QIcon_play_single width={40} height={40} /> : <QIcon_pause_single width={40} height={40} />}
              </button>
            </div>
            <div className={classNames(isMinimize && 'hidden', 'flex flex-col items-center')}>
              <div className="flex w-full flex-row justify-between">
                <div className="Body2-14Bd"></div>
                <button className="" onClick={handleMinimize}>
                  <QIcon_minimize width={20} height={20} />
                </button>
              </div>
              <div className="relative flex w-full flex-col items-center">
                {/* 재생바 시간이 지나면 채워지는 부분 */}
                <div className="mt-[24px] flex w-full items-center">
                  <div style={{ width: `${ratio}%` || '0px' }} className="h-[4px] rounded-[4px] bg-black"></div>
                  <div
                    style={{ width: `${100 - ratio}%` || '0px' }}
                    className=" h-[4px] w-full rounded-[4px] bg-gray-200 "
                  ></div>
                </div>
                <input
                  className="slider absolute top-[24px] z-[2] "
                  type="range"
                  min="0"
                  max="1"
                  step="any"
                  ref={rangeInputRef}
                  onMouseUp={(e) => handleRangeInputMouseUp(e, ref?.current)}
                  onMouseDown={handleRangeInputMouseDown}
                  onTouchStart={handleRangeInputMouseDown}
                  onTouchEnd={(e) => handleRangeInputMouseUp(e, ref?.current)}
                  value={isNaN(rangeValue) ? 0 : rangeValue}
                  onChange={(e) => handleRangeInputChange(e, ref?.current)}
                />
                <div className="mt-[10px] flex w-full flex-row justify-between">
                  {/* 현재 시간*/}
                  <div className="w-[48px]">
                    <div className="Caption-12Md text-gray-500">{formatTime(currentTime)}</div>
                  </div>
                  {/* 남은 시간 */}
                  <div className="">
                    <div className="Caption-12Md text-gray-500">{ref.current ? formatTime(+endTime) : endTime}</div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-row items-center justify-between">
                <MaterialAudioPlaybackRate
                  currentPlaybackRate={ref?.current?.playbackRate === 1 ? '1.0' : ref?.current?.playbackRate}
                  setPlaybackRate={handlePlaybackRate}
                />
                <div className="flex justify-center">
                  {/* 뒤로 가기 */}
                  <button onClick={handlePrevTimeClick} className="focus:outline-none">
                    <QIcon_back_5sec />
                  </button>
                  {/* 재생 버튼 */}
                  <button
                    data-ref={DATA_REF_KEY.btn_play}
                    onClick={(e) => handleStartButtonClick(e)}
                    className="px-[12px] focus:outline-none"
                  >
                    {isPause ? (
                      <QIcon_play_single width={48} height={48} />
                    ) : (
                      <QIcon_pause_single width={48} height={48} />
                    )}
                  </button>
                  {/* 앞으로 가기 */}
                  <button
                    data-ref={DATA_REF_KEY.btn_5s_foward}
                    onClick={handleNextTimeClick}
                    className=" focus:outline-none"
                  >
                    <QIcon_forward_5sec />
                  </button>
                </div>
                {/* 재생 속도 조절 */}

                <div className="flex flex-row gap-[8px]">
                  <button
                    onClick={toggleLooping}
                    className="border-1 flex h-[24px] w-[40px] justify-center rounded-[4px] border-gray-300 py-[2px] focus:outline-none"
                  >
                    {isLooping ? (
                      <QIcon_repeat color={COLORS.PURPLE500} width={20} height={20} />
                    ) : (
                      <QIcon_repeat width={20} height={20} />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="NewAudioPlayer-container flex w-full items-center rounded-[8px] border-gray-200 bg-white shadow-[0px_1px_4px_0px_rgba(0,0,0,0.15)]">
          <div className="NewAudioPlayer-block relative px-[28px]">
            {src && (
              <audio
                preload="auto"
                src={src}
                ref={ref}
                onLoadedData={handleAudioLoad}
                onEnded={handleAudioEnd}
                loop={isLooping}
              />
            )}
            <div className="flex flex-row items-center">
              <div className="flex justify-center">
                {/* 뒤로 가기 */}
                <button onClick={handlePrevTimeClick} className="focus:outline-none">
                  <QIcon_back_5sec />
                </button>
                {/* 재생 버튼 */}
                <button
                  data-ref={DATA_REF_KEY.btn_play}
                  onClick={(e) => handleStartButtonClick(e)}
                  className="px-[12px] focus:outline-none"
                >
                  {isPause ? (
                    <QIcon_play_single width={48} height={48} />
                  ) : (
                    <QIcon_pause_single width={48} height={48} />
                  )}
                </button>
                {/* 앞으로 가기 */}
                <button
                  data-ref={DATA_REF_KEY.btn_5s_foward}
                  onClick={handleNextTimeClick}
                  className=" focus:outline-none"
                >
                  <QIcon_forward_5sec />
                </button>
              </div>
              <div className="relative ml-[28px] mr-[20px] flex w-full flex-row items-center">
                {/* 현재 시간*/}
                <div className="w-[48px]">
                  <div className="pr-[4px] text-gray-700">{formatTime(currentTime)}</div>
                </div>
                {/* 재생바 시간이 지나면 채워지는 부분 */}
                <div
                  className={classNames(
                    isLanding ? 'lg:max-w-[368px]' : 'lg:max-w-[278px]',
                    'absolute right-[45px] flex w-full items-center overflow-y-hidden sm:max-w-[164px] sm:px-[8px] lg:max-w-[278px]'
                  )}
                  // style={{ maxWidth: rangeInputRef?.current?.offsetWidth }}
                >
                  <div className="relative flex flex-row"></div>
                  <div style={{ width: `${ratio}%` || '0px' }} className=" h-[4px] rounded-[4px] bg-black"></div>
                  <div
                    style={{ width: `${100 - ratio}%` || '0px' }}
                    className=" h-[4px] rounded-[4px] bg-gray-200"
                  ></div>
                </div>
                <div className=""></div>
                <input
                  className="slider z-[2]"
                  type="range"
                  min="0"
                  max="1"
                  step="any"
                  ref={rangeInputRef}
                  onMouseUp={(e) => handleRangeInputMouseUp(e, ref?.current)}
                  onMouseDown={handleRangeInputMouseDown}
                  onTouchStart={handleRangeInputMouseDown}
                  onTouchEnd={(e) => handleRangeInputMouseUp(e, ref?.current)}
                  value={isNaN(rangeValue) ? 0 : rangeValue}
                  onChange={(e) => handleRangeInputChange(e, ref?.current)}
                />
                {/* 남은 시간 */}
                <div className="">
                  <div className="pl-[4px]">{ref.current ? formatTime(+endTime) : endTime}</div>
                </div>
              </div>
              {/* 재생 속도 조절 */}
              {isLanding || (
                <div className="flex flex-row gap-[8px]">
                  <MaterialAudioPlaybackRate
                    className="Body2-14Md -1 flex h-[24px] w-[40px] justify-center border-gray-300 py-[2px]"
                    currentPlaybackRate={ref?.current?.playbackRate === 1 ? '1.0' : ref?.current?.playbackRate}
                    setPlaybackRate={handlePlaybackRate}
                  />
                  <button
                    onClick={toggleLooping}
                    className="border-1 flex h-[24px] w-[40px] justify-center rounded-[4px] border-gray-300 py-[2px] focus:outline-none"
                  >
                    {isLooping ? (
                      <QIcon_repeat color={COLORS.PURPLE500} width={20} height={20} />
                    ) : (
                      <QIcon_repeat width={20} height={20} />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
  }
)

MaterialAudioPlayer.displayName = 'AudioPlayer'

export default MaterialAudioPlayer
