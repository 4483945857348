import { TAB_TYPE, Tab } from '@repo/design-system/index'
import classNames from 'classnames'
import { memo, useState } from 'react'
import TextLinkHorizontal from '../../components/common/card/TextLinkHorizontal'
import useIsMobile from '../../modules/hooks/common/useIsMobile'
import { s_materials, s_preparation } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { TextProvider } from '../../pages/[lang]/tutor/portal/lessons/lesson-materials/[materialId]'
import EmptyBlock from '../common/EmptyBlock'
import ResizableImage from '../common/ResizableImage'
import ExpressionPanel from '../material/ExpressionPanel'
import MaterialInsightBody from '../material/MaterialInsightBody'

const QuestionPanel = ({ questions, isShort = false }) => {
  return (
    <div className="pb-[120px] sm:px-20 sm:pb-[0px] lg:px-0">
      <div className="">
        {questions?.map((item, i) => {
          if (isShort && i > 2) return //랜딩 컨텐츠 질문 3개만 보여줍니다.
          return (
            <div key={item.id} className="mb-[20px] border-b">
              <div className="mb-[48px] flex flex-row">
                <div className="">
                  <div className="Caption-12Bd rounded-[4px] bg-purple-50 px-[6px] py-[2px] text-purple-500">
                    Q{i + 1}
                  </div>
                </div>

                <div className="text-sBody md:text-16 px-[32px]">
                  {item.text ? item.text : item.content_main}
                  <div className="w-1/4">
                    {item.img_url ? (
                      <div className="mt-[24px]">
                        <ResizableImage url={item.img_url} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const MediaPanel = ({ media }) => {
  const { t } = useTranslation()
  return (
    <div className="w-full px-[0px] pb-[16px] sm:px-[20px] sm:px-[0px]">
      {media?.youtubes?.length > 0 && <div className="Title1-20Bd mb-[16px] text-gray-600">Video clip(s)</div>}
      <div className="mb-[32px] flex flex-col gap-[16px]">
        {media.youtubes?.map((item, i) => (
          <div className="aspect-[16/9]" key={i}>
            <iframe
              width="100%"
              height="100%"
              className="aspect-[16/9]"
              src={`https://www.youtube.com/embed/${item.code}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
      {media.articles?.length > 0 && <div className="Title1-20Bd mb-[16px] text-gray-600">Article(s)</div>}
      {media.articles?.map((item, i) => (
        <TextLinkHorizontal item={item} key={i} onClick={() => console.log('123')} />
      ))}
    </div>
  )
}
export const MaterialModalSubTab = ({ activeTabIdx, setActiveTabIdx }) => {
  const { t } = useTranslation()

  const SUB_MENU_LIST = [
    {
      label: t(s_preparation.insight),
    },
    {
      label: 'Expressions',
    },
    {
      label: t(s_materials.key_questions),
    },
    {
      label: t(s_materials.clip_article),
    },
  ]

  return (
    <div className="border-b-1 z-[20] ml-[0px] w-screen bg-white sm:w-full">
      <div className="mx-auto max-w-[1040px]">
        <Tab
          className="h-[46px] overflow-hidden"
          type={TAB_TYPE.SCROLL}
          borderNone={true}
          activeTabIdx={activeTabIdx}
          setActiveTabIdx={setActiveTabIdx}
        >
          {SUB_MENU_LIST.map((subMenuItem, i) => {
            return <Tab.TabItem name={subMenuItem.label} key={i} />
          })}
        </Tab>
      </div>
    </div>
  )
}

interface IMaterialBodyComponent {
  activeTabIdx: number
  materialData?
  materialId: any
  isInsightButton?: boolean
  isModal?: boolean
}

const NEW_TAB = {
  INSIGHT: 0,
  QUESTIONS: 2,
  MEDIA: 3,
  EXPRESSIONS: 1,
}

export const ModalBodyComponent = ({
  activeTabIdx,
  materialData,
  materialId,
  isModal = false,
}: IMaterialBodyComponent) => {
  const isMobile = useIsMobile()
  const media = {
    youtubes: materialData?.course.video,
    articles: materialData?.course.article,
  }

  switch (activeTabIdx) {
    case NEW_TAB.INSIGHT:
      return <MaterialInsightBody materialData={materialData} materialId={materialId} isModal={isModal} />
    case NEW_TAB.EXPRESSIONS:
      return <ExpressionPanel data={materialData} isShort={false} />
    case NEW_TAB.QUESTIONS:
      return (
        <div className={classNames(isMobile && 'px-[16px]')}>
          <QuestionPanel questions={materialData?.keyquestions} isShort={false} />
          <EmptyBlock h={60} />
        </div>
      )
    case NEW_TAB.MEDIA:
      return <MediaPanel media={media} />
    default:
      return <MaterialInsightBody materialData={materialData} materialId={materialId} />
  }
}

const MaterialModal = ({ data, isTrial = false, isMobile }: { data: any; isTrial?: boolean; isMobile?: boolean }) => {
  const [activeTabIdx, setActiveTabIdx] = useState<number>(0)

  return (
    <>
      {data && (
        <TextProvider>
          <div className="relative mx-auto h-full min-h-[calc(84vh_-_98px)] w-full border-gray-300">
            <div className="flex h-full w-full flex-col rounded-[4px]">
              <MaterialModalSubTab activeTabIdx={activeTabIdx} setActiveTabIdx={setActiveTabIdx} />
              <div className="mt-[40px]">
                <ModalBodyComponent
                  isModal={true}
                  activeTabIdx={activeTabIdx}
                  materialData={data}
                  materialId={data?.course?.id}
                />
              </div>
            </div>
          </div>
        </TextProvider>
      )}
    </>
  )
}

export default memo(MaterialModal)
