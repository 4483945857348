// @ts-strict-ignore
import { TAB_TYPE } from '@repo/design-system/index'
import classNames from 'classnames'
import { CurrentUser } from '../../../../../model/Common/common.interface'
import apis, { tutor_apis } from '../../../../../modules/apis'
import { axiosGet, axiosPost } from '../../../../../modules/axiosHelper'

// 답변 저장하기 버튼 클릭 시 - API 불러서 작성하고 있는 내용을 저장하고
//isEditable 을 false 로 설정해서 수정하기 상태로 갈 수 있도록
export const handleSubmitAnswer = async ({
  id,
  content,
  shareStatus,
  isEditable,
  setIsEditable,
  activeQuestionId,
  lessonId,
}) => {
  // content 아무것도 없을 수 없음 => 저장하기 버튼 비활성화임
  const payload = {
    coursekeyquestion_id: id,
    content: content,
    share_status: shareStatus, // 1: me, 0:public, 2: tutor and me
  }

  const response = await axiosPost(apis.lessons.preparation.answer(lessonId), payload)

  if (response.success) {
    // 해당 번째의 isEditable 을 false 로 만들어줌
    const updateIsEditable = [...isEditable]
    updateIsEditable.map((item, idx) => {
      if (Number(item.question_number) == activeQuestionId) {
        updateIsEditable[idx].student_answer.content = content
        updateIsEditable[idx].student_answer.share_status = shareStatus
        updateIsEditable[idx].editable = !item.editable
        setIsEditable(updateIsEditable)
      }
    })
  }
}

export const fetchData = async (lessonId) => {
  const response = await axiosGet(tutor_apis.lessons.preparation.getV5({ lessonId }))

  if (response) {
    return response
  }
}

// 답변 subTab
export const AnswerSubTab = ({ optionList, activeKey, setActiveKey, type }) => {
  return (
    <div className="border-b-1 z-[20] ml-[0px] lg:w-full">
      <div className="mx-[16px]">
        <div className="no-scrollbar flex">
          <div
            className={classNames(
              'bg-q-white border-q-gray-100 flex h-[52px] flex-row',
              type === TAB_TYPE.SCROLL ? 'justify-start ' : 'justify-evenly sm:justify-center'
            )}
          >
            {optionList.map((item, idx) => (
              <div
                key={idx}
                className={classNames(
                  'mb-[-1px] min-w-fit py-[13px] px-[8px]',
                  item.key === activeKey ? 'border-b-[2px] border-black text-black ' : 'cursor-pointer text-gray-300',
                  type === TAB_TYPE.SCROLL ? 'mx-[8px]' : 'mx-0 sm:mx-[68px] '
                )}
                onClick={() => {
                  if (item.key !== activeKey && setActiveKey) {
                    setActiveKey(item.key)
                  }
                }}
              >
                <div
                  className={classNames(
                    typeof item.label !== 'object' && 'Body1-16Bd leading-[26px]',
                    'flex items-center justify-start space-x-4'
                  )}
                >
                  <span className="text-16 font-bold text-black">{item.label}</span>
                  <span className="text-16 font-bold text-purple-500">{item.number}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * 다른 링글러들의 답변 정보 가져오는 함수.
 *
 */
export const getShareAnswer = async (answerId, setShareAnswers, setParticipantList) => {
  const response = await axiosGet(`${tutor_apis.lessons.preparation.getShareAnswerV5(answerId)}`)
  if (response) {
    console.log('getShareAnswer', response)
    setShareAnswers(response.answers)
    setParticipantList(response.participant_list)
  }
}

export interface QuestionSelectPanelPropsType {
  currentUser?: CurrentUser
  isReview?: boolean
  isLesson?: boolean
  isEditable: any
  setIsEditable: any
  setTabData?: any
  answer: any
  setAnswer: any
  selectedShareState: any
  setSelectedShareState: any
  activeQuestionId: any
  setActiveQuestionId: any
  id?: any
}

export const enum PREP_TABS {
  QUESTION = '수업 질문',
  NOTE = '수업 노트',
  MY_ANSWER = '내 답변',
  OTHER_ANSWER = '다른 링글러 답변',
}
