import { useToast } from '@repo/design-system/index'
import classNames from 'classnames'
import { useRef } from 'react'
import { useCountdownTimer } from '../../../modules/hooks/common/useCountdownTimer'
import { s_preparation } from '../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../modules/i18n/useTranslation'
import MaterialAudioPlayer from './MaterialAudioPlayer'

const MaterialDesktopBottomTab = ({ course, isPreparation = false, className = '', isLanding = false }) => {
  const { t } = useTranslation()
  const audioRef = useRef<HTMLAudioElement>(null)
  const { toastSuccess } = useToast()
  const { start, pause, isRunning } = useCountdownTimer({
    timer: 10000,
  })

  const handlePauseAudio = () => {
    pause()
  }
  const handlePlayAudio = () => {
    start()
  }
  const handleEndAudio = async () => {
    toastSuccess(t(s_preparation.listenMp3))
  }

  return (
    <>
      <div
        className={classNames(
          className,
          'right-[50%] hidden translate-x-1/2 lg:fixed lg:flex',
          course.mp3_link ? 'lg:justify-between' : 'lg:justify-end',
          'pointer-events-none bottom-[20px]  mx-auto min-h-[68px] w-full max-w-[688px]'
        )}
      >
        {course.mp3_link && (
          <div className={classNames('border-1 flex w-full rounded-[8px]', 'pointer-events-auto')}>
            <MaterialAudioPlayer
              isLanding={isLanding}
              onPlay={() => handlePlayAudio()}
              onPause={() => handlePauseAudio()}
              onEnd={handleEndAudio}
              onClickRange={(time) => console.log(time)}
              ref={audioRef}
              src={course.mp3_link}
              playbacktime={course?.playback_time ? course?.playback_time : 0}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default MaterialDesktopBottomTab
