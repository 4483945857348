// @ts-strict-ignore
import { QIcon_noconversation } from '@repo/icons/index'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import apis from '../../../../../modules/apis'
import { axiosPost } from '../../../../../modules/axiosHelper'
import useIsMobile from '../../../../../modules/hooks/common/useIsMobile'
import ViewAnswerItem from './LessonQuestionViewAnswerItem'
import Pagination from './Pagination'

type PeopleOpinionsPropsType = {
  answers: any
  participantList: any
  tutorAnswerComponent: any
  handleOpenOpinion: any
  activeQuestionId: any
  isClassroom?: boolean
}

const LessonQuestionPeopleOpinions = ({
  answers,
  participantList,
  tutorAnswerComponent,
  handleOpenOpinion,
  activeQuestionId,
  isClassroom = false,
}: PeopleOpinionsPropsType) => {
  const isMobile = useIsMobile(undefined, 640)
  const router = useRouter()
  const token = router.query.token as string
  const [loading, setLoading] = useState(false)

  const [myLikes, setMyLikes] = useState(
    answers.map((item, i) => {
      return {
        id: item.answer.id,
        like: !!item.my_like,
        likeCount: item.answer.like_count,
      }
    })
  )

  useEffect(() => {
    setMyLikes(
      answers.map((item, i) => {
        return {
          id: item.answer.id,
          like: !!item.my_like,
          likeCount: item.answer.like_count,
        }
      })
    )
  }, [activeQuestionId, answers])

  const myLike = (id) => {
    const likeItem = myLikes.find((item, i) => item.id === id)
    if (likeItem) {
      return likeItem.like
    } else {
      return null
    }
  }

  const getLikeCount = (id) => {
    const like = myLikes.find((item, i) => item.id === id)
    return like ? like.likeCount : 0
  }

  const handleUnlike = async (id) => {
    if (loading) return
    setLoading(true)
    const response = await axiosPost(
      apis.lesson.unlike(),
      {
        answer_id: id,
      },
      { initialToken: token }
    )

    if (response) {
      console.log(response, 'unlike response')
      setMyLikes(
        myLikes.map((item, i) => {
          if (item.id === id) {
            item.like = null
            item.likeCount -= 1
          }
          return item
        })
      )
    }
    setLoading(false)
  }

  const handleLike = async (id) => {
    if (loading) return
    setLoading(true)

    const response = await axiosPost(
      apis.lesson.like(),
      {
        answer_id: id,
      },
      { initialToken: token }
    )

    if (response) {
      console.log(response, 'like response')

      setMyLikes(
        myLikes.map((item, i) => {
          if (item.id === id) {
            item.like = response.like
            item.likeCount += 1
          }
          return item
        })
      )
    }
    setLoading(false)
  }

  const postsPerPage = 5
  const totalPosts = answers?.length
  const [activeIndex, setActiveIndex] = useState(0)
  const handlePagination = (number) => {
    setActiveIndex(number - 1)
  }

  const startIndex = activeIndex * postsPerPage
  const endIndex = startIndex + postsPerPage
  const currentAnswers = answers.slice(startIndex, endIndex)

  return (
    <div className="mt-[20px] flex-col space-y-8 sm:mt-0">
      {currentAnswers.length > 0 &&
        currentAnswers.map(
          (item, i) =>
            item.answer.content && (
              <div key={i}>
                {!isMobile && <>{i !== 0 && <div style={{ height: 1 }} className="m-16 bg-gray-200"></div>}</>}
                <ViewAnswerItem
                  item={item}
                  isShareAnswers={true}
                  likeCounts={getLikeCount(item.answer.id)}
                  onLikeClick={
                    myLike(item.answer.id) ? (e) => handleUnlike(item.answer.id) : (e) => handleLike(item.answer.id)
                  }
                  isIconActive={myLike(item.answer.id)}
                  customClassName={isMobile ? 'border-[1px] rounded-[8px] border-gray-200 p-[16px]' : ''}
                  isClassroom={isClassroom}
                />
              </div>
            )
        )}
      {answers.length < 1 && (
        <div className="mt-120 flex flex-col items-center justify-center space-y-20">
          <QIcon_noconversation className="h-64 w-64 text-gray-300" />
          <div className="fot-bold text-20 text-black">No answers have been written yet.</div>
        </div>
      )}
      {tutorAnswerComponent && <div className="mt-[32px]">{tutorAnswerComponent}</div>}
      {answers.length > 5 && (
        <div className="flex flex-row items-center justify-center gap-[8px] px-[20px] pb-[20px]">
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={totalPosts}
            paginate={handlePagination}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>
      )}
    </div>
  )
}

export default React.memo(LessonQuestionPeopleOpinions)
