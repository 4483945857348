import { createClient, SanityClient } from '@sanity/client'

// export default createClient({
//   projectId: process.env.SANITY_STUDIO_SANITY_PROJECT_ID, // you can find this in sanity.json
//   dataset: process.env.SANITY_STUDIO_SANITY_DATASET, // or the name you chose in step 1
//   useCdn: true, // `false` if you want to ensure fresh data
// })

const client: SanityClient = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || 'projectid',
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'dataset',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-17', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})

export default client
