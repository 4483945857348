// @ts-strict-ignore
import classNames from 'classnames'
import React, { useState } from 'react'

interface ResizableImageType {
  url: string
  beforeWidth?: any
  afterWidth?: any
}

const ResizableImage: React.FC<ResizableImageType> = (props) => {
  const [toggle, setToggle] = useState(false)
  const { beforeWidth = 235, afterWidth = 405, url } = props

  return (
    <div
      onClick={() => setToggle(!toggle)}
      style={{ width: toggle ? afterWidth : beforeWidth }}
      className={classNames(classNames('relative mt-5 cursor-pointer'))}
    >
      <img src={url} className="w-full rounded" />
      <div className="rounded-top-left font-size-sm absolute bottom-0 right-0 rounded bg-black px-4 text-white">
        {toggle ? <i className="fe fe-minus"></i> : <i className="fe fe-plus"></i>}
      </div>
    </div>
  )
}
export default ResizableImage
