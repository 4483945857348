import { QIcon_download, QIcon_file, QIcon_headphone, QIcon_text, QIcon_text_with_small_text } from '@repo/icons/index'
import classNames from 'classnames'
import { useContext, useEffect, useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import useTranslation from '../../modules/i18n/useTranslation'
import { COLORS } from '../../modules/vars'
import { TEXT_SIZE, TextContext } from '../../pages/[lang]/tutor/portal/lessons/lesson-materials/[materialId]'

const MaterialInsightTitleSection = ({ materialData, isLesson, isModal = false }) => {
  const { t, isLocaleKO } = useTranslation()
  const textRef = useRef(null)
  const downloadRef = useRef(null)
  const { showKorean, setShowKorean, textSize, setTextSize } = useContext(TextContext)
  const [showTextTooltip, setShowTextTooltip] = useState<boolean>(false)
  const [showDownloadTooltip, setShowDownloadTooltip] = useState<boolean>(false)
  const isTextSettingChanged = showKorean || textSize !== TEXT_SIZE.DEFAULT
  const cookies = new Cookies()
  const BUTTON_NOTICE_COOKIE = 'show_button_notice_tooltip'

  useEffect(() => {
    function handleClickOutside(event) {
      if (textRef.current && !textRef.current.contains(event.target)) {
        setShowTextTooltip(false)
      }
      if (downloadRef.current && !downloadRef.current.contains(event.target)) {
        setShowDownloadTooltip(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [textRef, downloadRef])

  const getLevel = (level = 0) => {
    switch (level) {
      case 0:
        return 'Basic'
      case 1:
        return 'Intermediate'
      case 2:
        return 'Advanced'
      case 3:
        return 'All'
    }
  }
  return (
    <div className="relative">
      {materialData.course && (
        <>
          <div className="flex h-full w-full flex-col">
            <div className="flex flex-col">
              <div className="mb-[12px] flex flex-row gap-[4px]">
                <div className="Caption-12Bd rounded-[4px] bg-gray-100 px-[6px] py-[2px] text-gray-700">
                  {materialData.course.category}
                </div>
              </div>
              <div className="Headline2-24Bd sm:Hero3-36Bd">
                {!showKorean
                  ? materialData.course.title_en
                    ? materialData.course.title_en
                    : materialData.course.title
                  : materialData.course.title_kr
                  ? materialData.course.title_kr
                  : materialData.course.title}
              </div>
              <div className="Title1-20Md">
                {!showKorean ? materialData.course.subtitle_en : materialData.course.subtitle_kr}
              </div>
              <div
                className={classNames(
                  isModal ? 'flex-col items-start justify-start gap-[12px]' : 'flex-row items-center justify-between',
                  'Body1-16Md mt-[20px] mb-[39px] flex text-gray-500'
                )}
              >
                <div className="flex flex-row">
                  <div className="Body1-16Md border-r-1 mr-[12px] pr-[12px] text-gray-500">
                    Updated: {materialData.course.published_at || materialData.course.updated_at}
                  </div>
                  <div className="">
                    {materialData.course.mp3_running_time_in_minute} min read · {getLevel(materialData.course.level)}
                  </div>
                </div>
                <div className={classNames(isModal ? 'hidden sm:flex' : 'hidden', 'flex-row gap-[10px] sm:flex')}>
                  <div className="relative" ref={textRef}>
                    <button
                      className={classNames(
                        isTextSettingChanged && 'border-purple-500',
                        'rounded-[7px] border-[1.5px] py-[11px] px-[10px]'
                      )}
                      onClick={() => {
                        cookies.remove(BUTTON_NOTICE_COOKIE)
                        setShowTextTooltip((prev) => !prev)
                      }}
                    >
                      <QIcon_text
                        width={24}
                        height={24}
                        color={isTextSettingChanged ? COLORS.PURPLE500 : COLORS.BLACK}
                      />
                    </button>

                    {showTextTooltip && (
                      <div
                        className={classNames(
                          isModal ? 'speech-bubble-modal right-[-136%]' : 'speech-bubble right-[50%]',
                          'absolute  top-[60px] z-[20] w-[224px] translate-x-2/4 rounded-[5px] bg-white shadow-md'
                        )}
                      >
                        <div className="border-1 rounded-[7px]">
                          <div className="p-[16px]">
                            <div className="mb-[12px] flex flex-row gap-[4px]">
                              <QIcon_text_with_small_text color={COLORS.GRAY500} width={20} height={20} />
                              <div className="Body2-14Md text-gray-700">Font Size</div>
                            </div>
                            <div className="Body2-14Md flex flex-row gap-[8px]">
                              <button
                                onClick={() => setTextSize(TEXT_SIZE.SMALLER)}
                                className={classNames(
                                  textSize === TEXT_SIZE.SMALLER && 'border-purple-500 bg-purple-50 text-purple-500',
                                  'rounded-[8px] border-[1.5px] py-[8px] px-[12px]'
                                )}
                              >
                                Small
                              </button>
                              <button
                                onClick={() => setTextSize(TEXT_SIZE.DEFAULT)}
                                className={classNames(
                                  textSize === TEXT_SIZE.DEFAULT && 'border-purple-500 bg-purple-50 text-purple-500',
                                  'rounded-[8px] border-[1.5px] py-[8px] px-[12px]'
                                )}
                              >
                                Normal
                              </button>
                              <button
                                onClick={() => setTextSize(TEXT_SIZE.BIGGER)}
                                className={classNames(
                                  textSize === TEXT_SIZE.BIGGER && 'border-purple-500 bg-purple-50 text-purple-500',
                                  'rounded-[8px] border-[1.5px] py-[8px] px-[12px]'
                                )}
                              >
                                Big
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {!isModal && (
                    <div className="relative" ref={downloadRef}>
                      <button
                        className="rounded-[7px] border-[1.5px] py-[11px] px-[10px]"
                        onClick={() => setShowDownloadTooltip((prev) => !prev)}
                      >
                        <QIcon_download width={24} height={24} color={COLORS.BLACK} />
                      </button>
                      {showDownloadTooltip && (
                        <div className="speech-bubble-download absolute right-[50%] top-[60px] z-[120] w-[156px] translate-x-2/4 rounded-[5px] bg-white shadow-md">
                          <div className="border-1 rounded-[5px] px-[20px] py-[9px]">
                            <div className="flex flex-row items-center justify-center gap-[20px]">
                              {materialData.course.mp3_link && (
                                <div className="flex flex-col items-center justify-center">
                                  <a
                                    rel="noreferrer"
                                    href={materialData.course.mp3_link}
                                    className="Caption-12Md flex flex-col items-center justify-center"
                                  >
                                    <div className=" flex items-center justify-center rounded-full bg-gray-100 p-[12px] text-gray-700">
                                      <QIcon_headphone />
                                    </div>
                                    MP3
                                  </a>
                                </div>
                              )}
                              {materialData.course.pdf_link && (
                                <div className="flex flex-col items-center justify-center">
                                  <a
                                    rel="noreferrer"
                                    href={materialData.course.pdf_link}
                                    className="Caption-12Md flex flex-col items-center justify-center"
                                  >
                                    <div className=" flex items-center justify-center rounded-full bg-gray-100 p-[12px] text-gray-700">
                                      <QIcon_file />
                                    </div>
                                    PDF
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {materialData.course.image_wide_url && (
        <div className="">
          <img src={materialData.course.image_wide_url} alt="material_image" className="w-full rounded-[8px]" />
        </div>
      )}
    </div>
  )
}

export default MaterialInsightTitleSection
