// @ts-strict-ignore
import { Popover, Transition } from '@headlessui/react'
import { DSBottomSheet } from '@repo/design-system/index'
import { QIcon_menu_button_kebab } from '@repo/icons/index'
import classNames from 'classnames'
import { Fragment, useState } from 'react'
import useIsMobile from '../../../../../modules/hooks/common/useIsMobile'
import useIsWebview from '../../../../../modules/hooks/common/useIsWebview'
import LinkLocale from '../../../../../modules/i18n/LinkLocale'

export interface Option {
  title: string
  icon: JSX.Element
  onClick?: (e: React.MouseEvent) => void
  href?: string
  disabled?: boolean
}

interface LessonMoreDropdownPropsType {
  className?: string
  optionList?: Option[]
  isLeft?: boolean
}

export const disableStyle = (option) => {
  if (option.disabled) {
    return 'bg-gray-50 text-gray-300 pointer-events-none'
  }
}

const LessonQuestionMoreDropdown = ({ className, optionList, isLeft = false }: LessonMoreDropdownPropsType) => {
  const handleChangeLessonMoreDropdown = (closePopover: () => void) => {
    closePopover()
  }

  const isMobile = useIsMobile()
  const isWebview = useIsWebview()
  const [showBottomSheet, setShowBottomSheet] = useState(false)

  const openBottomSheet: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setShowBottomSheet(true)
    if (isWebview) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'HIDE_TAB_BAR' }))
    }
  }

  const closeBottomSheet: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setShowBottomSheet(false)
    if (isWebview) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'SHOW_TAB_BAR' }))
    }
  }

  // isMobile 여부에 따라 렌더링을 다르게 처리
  const renderDropdown = ({ close }) => {
    return (
      <>
        {/* DROPDOWN MENU */}
        {optionList &&
          optionList.map((option, i) => {
            return option.href ? (
              <LinkLocale
                key={i}
                className={`flex py-8 px-11 hover:bg-gray-100 ${disableStyle(option)}`}
                href={option.href}
              >
                <div
                  style={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    lineHeight: '25px',
                    fontSize: '14px',
                  }}
                >
                  <div className="mr-[8px]">{option.icon}</div>
                  {option.title}
                </div>
              </LinkLocale>
            ) : option.onClick ? (
              /* click event */
              <div
                onClick={(e) => {
                  option.onClick(e)
                  handleChangeLessonMoreDropdown(close)
                }}
                key={i}
                className={`flex py-8 px-11 hover:bg-gray-100 ${disableStyle(option)}`}
              >
                <div
                  style={{
                    display: 'flex',
                    lineHeight: '25px',
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <div className="mr-[8px]">{option.icon}</div>
                  {option.title}
                </div>
              </div>
            ) : (
              /* no href, no click event */
              <div key={i} className={`pointer-events-none flex bg-gray-50 py-8 px-11 text-gray-300 hover:bg-gray-100`}>
                <div
                  style={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    lineHeight: '25px',
                    fontSize: '14px',
                  }}
                >
                  <div className="mr-[8px]">{option.icon}</div>
                  {option.title}
                </div>
              </div>
            )
          })}
      </>
    )
  }

  // isMobile, isWebview 여부에 따라 렌더링 결정
  return isMobile || isWebview ? (
    <Popover as="div" className="relative cursor-pointer self-center">
      <Popover.Button
        className={className + 'lift font-regular text-14 flex cursor-pointer rounded-md'}
        onClick={openBottomSheet}
      >
        <QIcon_menu_button_kebab />
      </Popover.Button>
      {showBottomSheet && (
        <DSBottomSheet
          id="public-option"
          isOpen={showBottomSheet}
          setIsOpen={setShowBottomSheet}
          closeOnDrag
          closeOnDim
          isWebview={isWebview}
          onClose={() => {
            if (isWebview) {
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'SHOW_TAB_BAR' }))
            }
          }}
        >
          <div className="z-20 flex h-full w-full flex-col">
            <div className="flex flex-col gap-[20px] pt-[4px] pb-[69px]">
              <div className="flex flex-col">
                {optionList.map((item, index) => (
                  <div key={index} className="flex justify-between">
                    <button
                      disabled={item?.disabled ? item?.disabled : false}
                      className={classNames(
                        'flex flex-row gap-x-[8px] py-[16px] px-[20px]',
                        item.disabled ? 'w-full bg-gray-50 text-gray-300' : 'w-full text-black'
                      )}
                      onClick={(e) => {
                        item.onClick(e)
                        closeBottomSheet(e)
                      }}
                    >
                      {item.icon}
                      {item.title}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </DSBottomSheet>
      )}
    </Popover>
  ) : (
    <>
      {/* Dropdown 렌더링 */}
      <Popover as="div" className="relative cursor-pointer self-center">
        {({ open }) => (
          <>
            <Popover.Button className={className + 'lift font-regular text-14 flex cursor-pointer rounded-md'}>
              <QIcon_menu_button_kebab />
            </Popover.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {/* Dropdown 패널 렌더링 */}
              <Popover.Panel
                className={classNames(
                  [isLeft ? 'right-[50%]' : ''],
                  'absolute z-20 rounded-[8px] bg-white p-0 py-[8px] shadow-[0px_2px_8px_rgba(0,0,0,0.15)]'
                )}
                style={{
                  bottom: '-calc(100% + 11px)',
                  backgroundColor: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '0',
                  marginTop: '8px',
                  boxSizing: 'border-box',
                  borderRadius: '5px',
                }}
              >
                {({ close }) => renderDropdown({ close })}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  )
}

export default LessonQuestionMoreDropdown
