// @ts-strict-ignore
import classNames from 'classnames'
import LinkLocale from '../../modules/i18n/LinkLocale'

type EmptyPagePropsType = {
  title: any
  href?: any
  label?: any
  targetBlank?: any
  imgUrl?: string
  dim?: any
  external?: any
}

const EmptyPage = ({ title, href, label, targetBlank, imgUrl, dim, external }: EmptyPagePropsType) => {
  return (
    <div>
      <div className="row mt-40 flex flex-col items-center justify-center">
        {imgUrl && (
          <div className="mb-6">
            <img
              src={imgUrl}
              width={175}
              style={{
                backgroundImage: 'url("/assets/img/icons/student/loghistory-bg.png")',
              }}
            />
          </div>
        )}
        <h3
          className={classNames(
            'mb-16 font-medium',
            { 'emptypage-text text-center text-white': dim },
            { 'text-center text-black': !dim }
          )}
        >
          {title}
        </h3>
        {external
          ? label && (
              <a
                target={targetBlank ? '_blank' : '_self'}
                href={href}
                className="btn lift mt-5 bg-purple-500 text-white"
                rel="noreferrer"
              >
                {label}
              </a>
            )
          : label && (
              <LinkLocale
                target={targetBlank ? '_blank' : '_self'}
                href={href}
                className="btn lift mt-5 bg-purple-500 text-white"
              >
                {label}
              </LinkLocale>
            )}
      </div>
    </div>
  )
}

export default EmptyPage
