// @ts-strict-ignore
import { BUTTON_SIZE, BUTTON_TYPE, DSBottomSheet, DSButton, DSModal, DSTextArea } from '@repo/design-system/index'
import { QIcon_close_default, QIcon_noconversation, QIcon_pen, QIcon_trash } from '@repo/icons/index'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import { tutor_apis } from '../../../../../modules/apis'
import { axiosPatch } from '../../../../../modules/axiosHelper'
import useIsMobile from '../../../../../modules/hooks/common/useIsMobile'
import useIsWebview from '../../../../../modules/hooks/common/useIsWebview'
import { COLORS } from '../../../../../modules/vars'
import { fetchCourseData } from '../LessonQuestionPanel'
import { useTranslatorSidePopup } from '../TranslatorSidePopup'
import LessonQuestionMyAnswerDeletePopupBody from './LessonQuestionMyAnswerDeletePopupBody'
import { fetchData } from './LessonQuestionQuestionSelectPanel'
import ViewAnswerItem from './LessonQuestionViewAnswerItem'
import Pagination from './Pagination'

interface MyAnswerHistoryProps {
  isEditable: any
  myAnswers: any
  setMyAnswers?: any
  id?: any
  activeQuestionId?: any
  refetch?: any
  isClassroom?: boolean
}

const LessonQuestionMyAnswerHistory = ({
  isEditable,
  myAnswers,
  setMyAnswers,
  id,
  activeQuestionId,
  refetch,
  isClassroom,
}: MyAnswerHistoryProps) => {
  const sidePopup = useTranslatorSidePopup()
  const isMobile = useIsMobile(undefined, 640)
  const isWebview = useIsWebview()
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const router = useRouter()
  const isMaterial = useMemo(() => router.asPath.includes('/lesson-materials/'), [router.asPath])

  const lessonId = router.query?.lessonId
    ? typeof router.query.lessonId === 'string'
      ? parseInt(router.query.lessonId, 10)
      : 0
    : undefined

  console.log(isEditable)

  // 수정하기 버튼을 눌렀을 때
  const MyAnswerEditModalBody = ({ item, isEditable, lessonId, setMyAnswers, setShowBottomSheet, setShowPopup }) => {
    console.log(
      'item ==========>',
      isEditable?.find((item) => item.question_number == activeQuestionId)
    )

    const [inputValue, setInputValue] = useState(item.content)
    const [inputShareStatus, setInputShareStatus] = useState(item.share_status)
    const coursekeyquestion_id = item.coursekeyquestion_id ? item.coursekeyquestion_id : item.id
    const question = isEditable?.find((item) => item.id == coursekeyquestion_id)?.content_main
      ? isEditable?.find((item) => item.id == coursekeyquestion_id)?.content_main
      : isEditable?.find((item) => item.question_number == activeQuestionId)?.content_main
    const question_number = isEditable?.find((item) => item.id == coursekeyquestion_id)?.question_number
    const [saveDisabled, setSaveDisabled] = useState(false)
    const [showConfirmPopup, setShowConfirmPopup] = useState(false)

    const handleSidePopupClose = () => {
      console.log(inputValue)

      if (inputValue === item.content) {
        // 하나도 수정하지 않은 경우 - 그냥 닫힘
        sidePopup.close('modify-popup')
        setShowBottomSheet(false)
        return
      } else if (inputValue == '') {
        // 사용자가 기존 작성했던 텍스트를 지우는 경우
        sidePopup.close('modify-popup')
        setShowBottomSheet(false)
        return
      } else {
        console.log('123')
        setShowPopup(true)
      }
    }

    const handleBottomSheetClose = () => {
      if (inputValue === item.content) {
        // 하나도 수정하지 않은 경우 - 그냥 닫힘
        setShowBottomSheet(false)
        return
      } else if (inputValue == '') {
        // 사용자가 기존 작성했던 텍스트를 지우는 경우
        setShowBottomSheet(false)
        return
      } else {
        setShowConfirmPopup(true)
      }
    }

    const handleModifyAnswer = async () => {
      const payload = {
        content: inputValue,
        share_status: inputShareStatus,
      }

      const response = await axiosPatch(tutor_apis.lessons.preparation.patch({ answerId: item.id }), payload)

      if (response) {
        sidePopup.close('modify-popup')
        setShowBottomSheet(false)
        if (!isMaterial) {
          const newData: any = await fetchData(lessonId)

          if (newData) {
            setMyAnswers(
              newData.lesson?.preparation?.student_answers
                ? newData.lesson?.preparation?.student_answers.filter((item) => item.is_final)
                : []
            )
          }
        } else {
          const newData: any = await fetchCourseData(id)

          if (newData) {
            setMyAnswers(newData?.filter((item) => item.question_number == activeQuestionId)[0]?.answers?.my_answers)
          }
        }
      }
    }

    useEffect(() => {
      if (inputValue === '') {
        setSaveDisabled(true)
      } else {
        setSaveDisabled(false)
      }
    }, [inputValue])

    console.log(showConfirmPopup, 'showConfirmPopup')
    return (
      <div className="flex h-full w-full flex-col pt-[40px]">
        <div className={classNames([isWebview ? 'justify-end' : 'mt-[20px]'], 'flex items-center pb-[16px]')}>
          {isWebview ? (
            <div>
              <QIcon_close_default onClick={handleBottomSheetClose} />
            </div>
          ) : (
            <>
              <div className="mr-auto"></div>
              <div className="whitespace-normal text-[20px] font-bold text-black" style={{ wordBreak: 'keep-all' }}>
                Edit answer
              </div>
              <div className="ml-auto">
                <button
                  type="button"
                  className="appearance-none border-none focus:outline-none"
                  style={{ width: 24, height: 24 }}
                  onClick={() => {
                    handleSidePopupClose()
                  }}
                >
                  <QIcon_close_default />
                </button>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="text-h2 mb-[8px] font-bold">
            Q{question_number}. {question}
          </div>
          {isWebview && (
            <>
              <div className="mb-[8px] text-[16px] font-medium">
                {isEditable.find((item) => item.question_number === question_number)?.content_main}
              </div>
            </>
          )}
          <div>
            <DSTextArea
              name={'TRANSLATE_TEXT_AREA'}
              value={inputValue}
              setValue={setInputValue}
              placeholder={'입력하세요'}
              autoFocus={true}
              customTextClassName={`mb-[40px] min-h-[233px] Body2-14Md`}
            />
            {showConfirmPopup && (
              <MyAnswerCancelPopupWebviewBody
                showPopup={showConfirmPopup}
                setShowPopup={setShowConfirmPopup}
                setShowBottomSheet={setShowBottomSheet}
              />
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <DSButton
            onClick={(e) => {
              handleModifyAnswer()
            }}
            size={BUTTON_SIZE.MD}
            customBgColor={COLORS.PURPLE600}
            disabled={saveDisabled}
          >
            <span className="font-bold text-white">Save</span>
          </DSButton>
        </div>
      </div>
    )
  }

  const MyAnswerCancelPopupWebviewBody = ({ showPopup, setShowPopup, setShowBottomSheet }) => {
    const handleOnCloseButtonClick = (e) => {
      e.stopPropagation(e)
      setShowPopup(false)
    }

    const handleOnApplyButtonClick = (e) => {
      e.stopPropagation()
      setShowPopup(false)
      setShowBottomSheet(false)
    }
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.4)] bg-opacity-50">
        <div className="border-1 m-16 flex flex-col space-y-24 rounded-[12px] border-white bg-white p-32">
          <div className="flex flex-col space-y-12">
            <span className="text-[18px] font-bold text-black">
              You have unsaved memo(s).
              <br />
              Leave anyway?
            </span>
            <span className="text-[14px] font-medium text-gray-700">
              If you leave without saving, the content you have written so far will be lost.
            </span>
          </div>
          <div className="flex w-full justify-center space-x-8">
            <div className="w-full">
              <DSButton
                size={BUTTON_SIZE.MD}
                type={BUTTON_TYPE.OUTLINE}
                customBgColor={COLORS.WHITE}
                onClick={handleOnCloseButtonClick}
              >
                <DSButton.Label>Cancel</DSButton.Label>
              </DSButton>
            </div>
            <div className="w-full">
              <DSButton size={BUTTON_SIZE.MD} onClick={handleOnApplyButtonClick}>
                <DSButton.Label>Exit</DSButton.Label>
              </DSButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // 답변 수정하기 팝업 열기
  const handleMyAnswerModifyButton = ({ e, item }) => {
    console.log(item)

    e.preventDefault()
    if (!isWebview) {
      sidePopup.show({
        id: 'modify-popup',
        overlayClose: false,
        noTopSide: true,
        hFull: true,
        maxW: 448,
        body: (
          <MyAnswerEditModalBody
            item={item}
            isEditable={isEditable}
            lessonId={lessonId ? lessonId : id}
            setMyAnswers={setMyAnswers}
            setShowBottomSheet={setShowBottomSheet}
            setShowPopup={setShowPopup}
          />
        ),
      })
    } else {
      setCurrentAnswerItem(item)
      setShowBottomSheet(true)
    }
  }

  const [showDeletePopup, setShowDeletePopup] = useState(false)

  const optionList = ({ item }) => [
    {
      title: 'Edit',
      icon: <QIcon_pen />,
      onClick: (e) => {
        handleMyAnswerModifyButton({ e, item })
      },
    },
    {
      title: 'Delete',
      icon: <QIcon_trash />,
      onClick: (e) => {
        setShowDeletePopup(true)
        setCurrentAnswerItem(item)
      },
    },
  ]

  const postsPerPage = 5
  const totalPosts = myAnswers?.length
  const [activeIndex, setActiveIndex] = useState(0)
  const handlePagination = (number) => {
    setActiveIndex(number - 1)
  }

  const startIndex = activeIndex * postsPerPage
  const endIndex = startIndex + postsPerPage
  const currentAnswers = myAnswers?.slice(startIndex, endIndex)
  const [currentAnswerItem, setCurrentAnswerItem] = useState(currentAnswers?.length >= 1 ? currentAnswers[0] : {})
  console.log(currentAnswers, 'currentAnswers')
  return (
    <div className="mt-[20px] h-full flex-col space-y-8 sm:mt-0">
      {currentAnswers?.length > 0 &&
        currentAnswers?.reverse().map((item, i) => (
          <div key={i}>
            {i !== 0 && <div style={{ height: 1 }} className="m-16 bg-gray-200"></div>}
            <ViewAnswerItem
              item={item}
              optionList={optionList({ item })}
              customClassName={isMobile ? 'border-[1px] rounded-[8px] border-gray-200 p-[16px]' : ''}
              likeCounts={item.like_count}
              isClassroom={isClassroom}
            />
            {showBottomSheet && (
              <DSBottomSheet
                id={`bottom-sheet-${currentAnswerItem?.id}`}
                isOpen={showBottomSheet}
                setIsOpen={setShowBottomSheet}
              >
                <MyAnswerEditModalBody
                  item={currentAnswerItem}
                  isEditable={isEditable}
                  lessonId={lessonId ? lessonId : id}
                  setMyAnswers={setMyAnswers}
                  setShowBottomSheet={setShowBottomSheet}
                  setShowPopup={setShowPopup}
                />
              </DSBottomSheet>
            )}
            {/* Delete Modal Body */}
            <LessonQuestionMyAnswerDeletePopupBody
              showPopup={showDeletePopup}
              setShowPopup={setShowDeletePopup}
              answerId={currentAnswerItem?.id}
              setMyAnswers={setMyAnswers}
              isMaterial={isMaterial}
              lessonId={lessonId}
              id={id}
              activeQuestionId={activeQuestionId}
            />
          </div>
        ))}
      {myAnswers?.length < 1 && (
        <div className="mt-120 flex flex-col items-center justify-center space-y-20">
          <QIcon_noconversation className="h-64 w-64 text-gray-300" />
          <div className="fot-bold text-20 text-black">No answers have been written yet.</div>
        </div>
      )}
      {myAnswers?.length > 5 && (
        <div className="flex flex-row items-center justify-center gap-[8px] px-[20px] pb-[20px]">
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={totalPosts}
            paginate={handlePagination}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>
      )}
      <DSModal id="TextOnlySMTwoButtonHorizontalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size={'lg'}>
        <DSModal.Title>
          <div className="flex flex-wrap text-[18px]">You have unsaved memo(s) Leave anyway?</div>
        </DSModal.Title>
        <DSModal.Content>
          <div className="Body1-16Md">The memo(s) will be lost if you leave without saving.</div>
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton
            size={BUTTON_SIZE.MD}
            type={BUTTON_TYPE.OUTLINE}
            customBgColor={COLORS.WHITE}
            onClick={() => setShowPopup(false)}
          >
            <DSButton.Label>Cancel</DSButton.Label>
          </DSButton>
          <DSButton
            size={BUTTON_SIZE.MD}
            onClick={() => {
              setShowPopup(false)
              setShowBottomSheet(false)
              sidePopup.close('modify-popup')
            }}
          >
            <DSButton.Label>Leave</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    </div>
  )
}

export default React.memo(LessonQuestionMyAnswerHistory)
