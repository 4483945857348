import { BUTTON_SIZE, BUTTON_TYPE, DSButton } from '@repo/design-system/index'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { SetStateAction, useMemo, useState } from 'react'
import useIsMobile from '../../../../../modules/hooks/common/useIsMobile'
import { LOCALE_EN, LOCALE_KO } from '../../../../../modules/i18n/config'
import useTranslation from '../../../../../modules/i18n/useTranslation'
import { COLORS } from '../../../../../modules/vars'
import { DivPropType } from '../../../../basic/DivPropsType'
import GoogleTranslate from '../../../../common/GoogleTranslate'

interface CardTextareaPropsType extends DivPropType {
  inputref?: any
  cardClass?: any
  bodyClass?: any
  className?: any
  disabled?: any
  style?: any
  noBorder?: any
  rounded?: any
  children?: any
  dataRef?: string
}

export const CardTextarea = ({
  inputref,
  cardClass,
  bodyClass,
  className,
  disabled,
  style,
  noBorder,
  rounded,
  children,
  dataRef,
  ...others
}: CardTextareaPropsType) => {
  return (
    <>
      <div className={classNames(classNames('', bodyClass))}>
        <textarea
          disabled={disabled}
          className={classNames(
            'Body2-14Md scrollbar-new` h-[142px] w-[320px] w-full resize-none rounded-[8px] !border-none bg-gray-50 p-[16px] !ring-0',
            disabled ? 'text-gray-400' : 'text-black',
            className
          )}
          {...others}
        >
          {children}
        </textarea>
      </div>
    </>
  )
}
export interface CoursekeyquestionsReordering {
  id: number
  question_number: string | number
  student_choice: boolean
  content_main: string
  content_sub: null | string
  img_url_1: null | string
  student_answer: any | null
  requested_text: null
  requested_texts_of_ckq: any[]
  revised_texts_of_ckq: any[]
  revised_texts_of_this_lesson: any[]
}
interface isEditable extends CoursekeyquestionsReordering {
  editable: boolean
}

export const SHARE_STATUS = {
  0: 'Everyone',
  1: 'Tutor and Me',
  2: 'Only Me',
}

export enum ANSWER_SHARE_STATUS {
  PUBLIC = 0,
  TUTOR_AND_ME = 1,
  ME = 2,
}

export const shareOptions = (isMaterial) => {
  const options = [
    { label: 'Everyone', value: ANSWER_SHARE_STATUS.PUBLIC },
    { label: 'Only Me', value: ANSWER_SHARE_STATUS.ME },
  ]

  if (!isMaterial) {
    options.push({ label: 'Tutor and Me', value: ANSWER_SHARE_STATUS.TUTOR_AND_ME })
  }

  return options
}

interface AnswerItemPropsType {
  answer: string | null | undefined
  setAnswer: (value: string | null | undefined) => void
  isEditable: any
  setIsEditable: (value: SetStateAction<isEditable[] | null>) => void
  activeQuestionId: number
  lessonId: number
  isReview: boolean
  isLesson: boolean
  isPast: () => boolean
  handleSubmitAnswer?: any
  onBlur: () => void
  selectedShareState: any
  setSelectedShareState: any
}

const LessonQuestionAnswerItem = ({
  answer,
  setAnswer,
  isEditable,
  setIsEditable,
  activeQuestionId,
  lessonId,
  handleSubmitAnswer,
  onBlur,
  isReview,
  isLesson,
  isPast,

  selectedShareState,
}: AnswerItemPropsType) => {
  console.log(answer, 'answer')
  const { locale } = useTranslation()
  const isMobile = useIsMobile(undefined, 1041)
  const router = useRouter()
  const isMaterial = useMemo(() => router.asPath.includes('/lesson-materials/'), [router.asPath])
  const isClassroom = useMemo(() => router.asPath.includes('/classroom/'), [router.asPath])

  const currentAnswer = isEditable?.find((item, idx) => Number(item.question_number) == activeQuestionId)
  const currentIsEditable = isEditable?.find((item, idx) => Number(item.question_number) == activeQuestionId)
  const [showGoogleTranslate, setShowGoogleTranslate] = useState(false)

  const onChangeAnswer = ({ target }) => {
    // CardTextarea에서 입력될떼 setAnswer을 함.
    setAnswer(target.value)
  }

  // 수정 하기 버튼 (input 활성화)
  const handleModifyAnswer = () => {
    // activeQuestionId 에 맞게 setIsEditable 사용
    const updateIsEditable = [...isEditable]
    updateIsEditable.map((item, idx) => {
      if (Number(item.question_number) == activeQuestionId) {
        updateIsEditable[idx].editable = !item.editable
        setIsEditable(updateIsEditable)
      }
    })
  }

  const IS_INPUT_NOT_WRITABLE = isLesson
  console.log(currentIsEditable, 'currentIsEditable')
  console.log(isMaterial, 'isMaterial')
  return (
    <>
      {(locale == LOCALE_KO || locale == LOCALE_EN) && showGoogleTranslate && (
        <GoogleTranslate lessonId={lessonId} onClose={() => setShowGoogleTranslate(false)} />
      )}
      <div>
        <CardTextarea
          id={`answer-text-field-${activeQuestionId}`}
          disabled={IS_INPUT_NOT_WRITABLE || !currentIsEditable?.editable || isReview || isClassroom}
          onChange={(e) => {
            onChangeAnswer(e)
          }}
          onBlur={onBlur}
          minRows={4}
          value={answer}
          placeholder={
            isLesson || isClassroom ? 'No answer saved.' : isPast() ? '' : 'Write down your thoughts on the question.'
          }
          className={classNames({
            'bg-gray-50': !currentIsEditable?.editable,
          })}
        />
      </div>
      {!isClassroom && !isPast() && (
        <div className="row mt-[4px]">
          <div className={classNames('flex flex-col', isMobile ? 'space-y-12' : 'space-y-4')}>
            <div className={classNames('flex items-center justify-between space-x-[8px]', isMobile ? '' : 'mt-0')}>
              <div className="Body2-14Md">
                Your responses will be made public to all Ringle students for the benefit of their learning.
              </div>
              <div className={classNames(isMobile ? 'w-full' : '')}>
                {currentIsEditable?.editable ? ( // 수정하기 버튼을 누르거나 사용자가 답변을 입력 했을 때
                  <div>
                    <DSButton
                      onClick={(e) => {
                        if (!isMaterial) {
                          handleSubmitAnswer({
                            course_id: lessonId, // new
                            question_number: activeQuestionId, // new
                            id: currentAnswer.id,
                            content: answer,
                            shareStatus: selectedShareState || 0,
                            isEditable,
                            setIsEditable,
                            activeQuestionId,
                            lessonId,
                          })
                        } else {
                          // 교재에서 답변 다는 경우
                          handleSubmitAnswer({
                            course_id: lessonId, // to-do: lessonId 대신 다른 파라미터 이름 사용?
                            question_number: activeQuestionId,
                            content: answer,
                            shareStatus: selectedShareState || 0,
                            isEditable,
                            setIsEditable,
                          })
                        }
                      }}
                      size={BUTTON_SIZE.MD}
                      type={BUTTON_TYPE.SOLID}
                      customBorderColor={COLORS.PURPLE600}
                      customBgColor={COLORS.PURPLE600}
                      className="border-[1px]"
                      disabled={answer ? false : true}
                    >
                      <DSButton.Label>Save</DSButton.Label>
                    </DSButton>
                  </div>
                ) : (
                  <div>
                    <DSButton
                      onClick={(e) => {
                        handleModifyAnswer()
                      }}
                      size={BUTTON_SIZE.MD}
                      type={BUTTON_TYPE.OUTLINE}
                      customBorderColor={COLORS.PURPLE600}
                      disabled={isReview}
                    >
                      <DSButton.Label customTxtColor={COLORS.PURPLE600}>Edit</DSButton.Label>
                    </DSButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default LessonQuestionAnswerItem
