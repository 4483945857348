import { DSBottomSheet, Switch, useToast } from '@repo/design-system/index'
import {
  QIcon_chat,
  QIcon_download,
  QIcon_play_outline,
  QIcon_text,
  QIcon_text_with_small_text,
} from '@repo/icons/index'
import classNames from 'classnames'
import { useContext, useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import { useCountdownTimer } from '../../../modules/hooks/common/useCountdownTimer'
import { s_preparation } from '../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../modules/i18n/useTranslation'
import { COLORS } from '../../../modules/vars'
import { TEXT_SIZE, TextContext } from '../../../pages/[lang]/tutor/portal/lessons/lesson-materials/[materialId]'
import MaterialAudioPlayer from './MaterialAudioPlayer'

const TYPE = { NONE: 'none', AUDIO: 'audio', TEXT: 'text', DOWNLOAD: 'download' }
const BottomSheetComponent = ({
  course,
  bottomSheetType,
  setShowDownloadBottomSheet,
  showDownloadBottomSheet,
  setShowTextBottomSheet,
  showTextBottomSheet,
  hideTextButton,
  hideDownloadButton,
}) => {
  const { isLocaleKO } = useTranslation()
  const { showKorean, setShowKorean, textSize, setTextSize } = useContext(TextContext)

  const handleMp3ButtonClick = (link) => {
    window.open(link, '_blank')
  }

  const handlePdfClick = (link) => {
    window.open(link, '_blank')
  }

  switch (bottomSheetType) {
    case TYPE.TEXT:
      return (
        <>
          {showTextBottomSheet && (
            <DSBottomSheet
              noDim={true}
              enableScroll={true}
              closeOnDim={true}
              id="textSettingBottomSheet"
              isOpen={showTextBottomSheet}
              setIsOpen={setShowTextBottomSheet}
            >
              <div className="">
                {isLocaleKO && (
                  <>
                    <div className="flex flex-col items-center justify-center p-[16px]">
                      <div className="flex w-full flex-row justify-between">
                        <div className="flex flex-row items-center gap-[4px]">
                          <QIcon_chat width={20} height={20} />
                          <div className="Body2-14Md text-gray-700">한글 같이 보기</div>
                        </div>
                        <div className=" flex items-center">
                          <Switch checked={showKorean} setChecked={setShowKorean}></Switch>
                        </div>
                      </div>
                    </div>
                    <hr className="mx-[16px] bg-gray-200" />
                  </>
                )}
                <div className="flex flex-row items-center justify-between p-[16px]">
                  <div className="flex  flex-row gap-[4px] text-gray-700">
                    <QIcon_text_with_small_text color={COLORS.GRAY500} width={20} height={20} />
                    <div className="Body2-14Md">Font Size</div>
                  </div>
                  <div className="Body2-14Md flex flex-row gap-[8px]">
                    <button
                      onClick={() => setTextSize(TEXT_SIZE.SMALLER)}
                      className={classNames(
                        textSize === TEXT_SIZE.SMALLER && 'border-purple-500 bg-purple-50 text-purple-500',
                        'rounded-[8px] border-[1.5px] py-[8px] px-[12px]'
                      )}
                    >
                      Small
                    </button>
                    <button
                      onClick={() => setTextSize(TEXT_SIZE.DEFAULT)}
                      className={classNames(
                        textSize === TEXT_SIZE.DEFAULT && 'border-purple-500 bg-purple-50 text-purple-500',
                        'rounded-[8px] border-[1.5px] py-[8px] px-[12px]'
                      )}
                    >
                      Normal
                    </button>
                    <button
                      onClick={() => setTextSize(TEXT_SIZE.BIGGER)}
                      className={classNames(
                        textSize === TEXT_SIZE.BIGGER && 'border-purple-500 bg-purple-50 text-purple-500',
                        'rounded-[8px] border-[1.5px] py-[8px] px-[12px]'
                      )}
                    >
                      Big
                    </button>
                  </div>
                </div>
              </div>
            </DSBottomSheet>
          )}
        </>
      )
    case TYPE.DOWNLOAD:
      return (
        <>
          {showDownloadBottomSheet && (
            <DSBottomSheet
              noDim={true}
              enableScroll={true}
              closeOnDim={true}
              id="donwloadBottomSheet"
              isOpen={showDownloadBottomSheet}
              setIsOpen={setShowDownloadBottomSheet}
            >
              <div className="px-[20px] pb-[16px]">
                <div className="flex flex-row items-center justify-center gap-[8px]">
                  {course.mp3_link && (
                    <button className="Body2-14Bd border-1 flex w-full flex-col items-center justify-center rounded-[7px] border-gray-300 py-[12px] px-[28px]">
                      <div
                        onClick={() => handleMp3ButtonClick(course.mp3_link)}
                        className="Caption-12Md flex flex-col items-center justify-center"
                      >
                        Download MP3
                      </div>
                    </button>
                  )}
                  {course.pdf_link && !hideTextButton && (
                    <button className="Body2-14Bd border-1 flex w-full flex-col items-center justify-center rounded-[7px] border-gray-300 py-[12px] px-[28px]">
                      <div
                        onClick={() => handlePdfClick(course.pdf_link)}
                        className="Caption-12Md flex flex-col items-center justify-center"
                      >
                        Download PDF
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </DSBottomSheet>
          )}
        </>
      )
    default:
      return <></>
  }
}
const MaterialMobileBottomTab = ({ course, hideTextButton = false, isLanding = false, hideDownloadButton = false }) => {
  const { t } = useTranslation()
  const [bottomSheetType, setBottomSheetType] = useState(TYPE.NONE)

  const [showTextBottomSheet, setShowTextBottomSheet] = useState(false)
  const [showDownloadBottomSheet, setShowDownloadBottomSheet] = useState(false)

  const { showKorean, textSize } = useContext(TextContext)
  const isTextSettingChanged = showKorean || textSize !== TEXT_SIZE.DEFAULT

  const audioRef = useRef<HTMLAudioElement>(null)
  const { toastSuccess } = useToast()
  const { start, pause, isRunning } = useCountdownTimer({
    timer: 10000,
  })

  const handlePauseAudio = () => {
    pause()
  }
  const handlePlayAudio = () => {
    start()
  }
  const handleEndAudio = () => {
    toastSuccess(t(s_preparation.listenMp3))
  }

  const handleEndButton = () => {
    setBottomSheetType(TYPE.NONE)
  }

  const cookies = new Cookies()
  const BUTTON_NOTICE_COOKIE = 'show_button_notice_tooltip'
  const showButtonNoitceTooltip = cookies.get(BUTTON_NOTICE_COOKIE) === 'close'

  const DefaultLayout = () => {
    return (
      <div className="border-t-1 fixed bottom-[0px] z-[0] flex h-[88px] w-full flex-row items-center justify-center gap-[48px] bg-white pb-[28px] sm:pb-[0px] lg:hidden">
        <button className="" onClick={() => setBottomSheetType(TYPE.AUDIO)}>
          <QIcon_play_outline width={32} height={32} />
        </button>
        {hideTextButton ? (
          <div className="w-[32px]"></div>
        ) : (
          <div className="flex items-center justify-center">
            <button
              className=""
              onClick={() => {
                setShowTextBottomSheet(true)
                setBottomSheetType(TYPE.TEXT)
              }}
            >
              <QIcon_text width={32} height={32} color={isTextSettingChanged ? COLORS.PURPLE500 : COLORS.BLACK} />
            </button>
          </div>
        )}
        {!hideDownloadButton && !isLanding && (
          <button
            className=""
            onClick={() => {
              setShowDownloadBottomSheet(true)
              setBottomSheetType(TYPE.DOWNLOAD)
            }}
          >
            <QIcon_download width={32} height={32} />
          </button>
        )}
      </div>
    )
  }

  return (
    <div className="fixed bottom-[0px] z-[20]">
      <div className={bottomSheetType === TYPE.AUDIO ? 'block' : 'hidden'}>
        {course.mp3_link && (
          <MaterialAudioPlayer
            isLanding={isLanding}
            onClickEndButton={handleEndButton}
            isMobile={true}
            onPlay={() => handlePlayAudio()}
            onPause={() => handlePauseAudio()}
            onEnd={handleEndAudio}
            onClickRange={(time) => console.log(time)}
            ref={audioRef}
            src={course.mp3_link}
            playbacktime={course?.playback_time ? course?.playback_time : 0}
          />
        )}
      </div>

      <BottomSheetComponent
        course={course}
        bottomSheetType={bottomSheetType}
        showTextBottomSheet={hideTextButton ? false : showTextBottomSheet}
        setShowTextBottomSheet={hideTextButton ? () => null : setShowTextBottomSheet}
        showDownloadBottomSheet={showDownloadBottomSheet}
        setShowDownloadBottomSheet={setShowDownloadBottomSheet}
        hideTextButton={hideTextButton}
        hideDownloadButton={hideDownloadButton}
      />
      <DefaultLayout />
    </div>
  )
}

export default MaterialMobileBottomTab
