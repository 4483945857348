import { QIcon_heart_filled, QIcon_heart_outline } from '@repo/icons/index'
import React from 'react'
import useTranslation from '../../../../../modules/i18n/useTranslation'
import DateComponent from '../../../../DateComponent'
import { ANSWER_SHARE_STATUS } from './LessonQuestionAnswerItem'
import LessonMoreDropdown from './LessonQuestionMoreDropdown'

interface ViewAnswerItemProps {
  item: any
  isShareAnswers?: boolean
  locale?: 'ko' | 'en' | 'zh_chs' | 'zh_cht' | 'ja' | 'vi' | 'key'
  optionList?: any[]
  likeCounts?: number
  onLikeClick?: any
  isIconActive?: boolean
  customClassName?: string
  isClassroom?: boolean
}

// 내 답변과 다른 링글러의 답변 보는 컴포넌트
const ViewAnswerItem = ({
  item,
  isShareAnswers = false,
  locale,
  optionList,
  likeCounts,
  onLikeClick,
  isIconActive,
  customClassName,
  isClassroom = false,
}: ViewAnswerItemProps) => {
  const { t } = useTranslation()
  const created_at = item.created_at
  const content = item.answer ? item.answer.content : item.content
  const share_status =
    item.share_status === ANSWER_SHARE_STATUS.ME
      ? 'Only Me'
      : item.share_status === ANSWER_SHARE_STATUS.PUBLIC
      ? 'Everyone'
      : 'Tutor and Me'
  const isTutor = item.author?.role == 'Tutor'

  console.log('item ===========> ', item)

  const contentWithBreaks = content.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))

  return (
    <div className={`flex flex-col space-y-16 ${customClassName && customClassName}`}>
      <div className="flex w-full flex-col space-y-4 px-20 py-16">
        {/** 날짜 및 버튼 */}
        <div className="flex w-full">
          <div className="flex space-x-8">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row">
                {isShareAnswers && <div className="font-bold ">ID {item.author.name}</div>}
                {isTutor && (
                  <div className="Caption-12Bd ml-[8px] rounded-[4px] bg-gray-100 px-[6px] pt-[3px] text-gray-600">
                    Tutor
                  </div>
                )}
              </div>
              <div className="flex space-x-8">
                {isShareAnswers ? (
                  <span className="text-[14px] font-medium text-gray-500">{created_at}</span>
                ) : (
                  <DateComponent
                    format="yyyy.MM.dd"
                    date={created_at}
                    locale={locale && locale}
                    className="text-16 font-medium text-gray-500"
                  />
                )}
                {item.answer?.source_origin == 'DAILY_STUDY' && (
                  <div className="text-12 rounded-[4px] bg-purple-50 px-6 py-2 font-bold text-purple-600">
                    Daily Activity
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isClassroom && !isShareAnswers && (
            <div className="ml-auto">
              <LessonMoreDropdown optionList={optionList} isLeft={true} />
            </div>
          )}
        </div>
        {/** 답변 body */}
        <div className="font-16 font-medium text-black">{contentWithBreaks}</div>
      </div>
      {/* 좋아요 */}
      <div className="flex px-20">
        <div className="w-full"></div>
        <div className="ml-auto flex space-x-4">
          {!isIconActive ? (
            <QIcon_heart_outline onClick={onLikeClick} className="cursor-pointer" />
          ) : (
            <QIcon_heart_filled onClick={onLikeClick} className="cursor-pointer" />
          )}
          <div className="text-14 font-medium text-gray-500">{likeCounts}</div>
        </div>
      </div>
    </div>
  )
}

export default ViewAnswerItem
