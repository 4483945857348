import { s_materials } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { SPEAKING_FOCUSED_FOR_INTERVIEW } from '../../modules/vars'

const FreeTalkingInsightBody = ({ data }: { data: any }) => {
  const { t, isLocaleKO } = useTranslation()
  console.log(data.course)

  const questionList = (
    <>
      1. Tell me about yourself.
      <br />
      2. Why do you want to work here?
      <br />
      3. How did you hear about the position?
      <br />
      4. What is your greatest strength?
      <br />
      5. What is your greatest weakness?
      <br />
      6. How would you describe your ideal company?
      <br />
      7. How would you describe your ideal boss?
      <br />
      8. How would you describe your leadership style?
      <br />
      9. How do you deal with pressure?
      <br />
      10. Let’s talk about a time you experienced failure. How did you respond to it?
      <br />
      11. Let’s talk about a time you made a mistake. How did you respond to it?
      <br />
      12. Let’s talk about a time you disagreed with a decision. What did you do?
      <br />
      13. What were your primary responsibilities in your last position?
      <br />
      14. Why are you leaving your present job?
      <br />
      15. What can you do for us that other candidates can't?
      <br />
      16. What motivates you?
      <br />
      17. Who is someone you look up to, and why?
      <br />
      18. What is your greatest accomplishment or work-related achievement thus far?
      <br />
      19. Let’s talk about an experience that shaped you. How has this changed the way you work?
      <br />
      20. Where would you like to be in your career five years from now? How about in ten years?
      <br />
      21. How well do you work with people who are different from you?
      <br />
      22. Let’s talk about a time that you had to work in a team. Did you run into any difficulties? Why?
      <br />
      23. What should we expect from you in your first three months?
      <br />
      24. Is there anything I should know about you that’s not on your resume?
      <br />
      25. Do you have any questions for me?
      <br />
    </>
  )

  return (
    <>
      <div className="text-h5 mt-[20px] text-gray-700">
        {data.course.is_writing ? (
          <div>
            <div className="Hero3-36Bd mb-[32px]">
              {data.course.title_en ? data.course.title_en : data.course.title}
            </div>

            <div className="text-h2 border-b pb-[32px] text-black">{t(s_materials.writing_focused_guide_title)}</div>
            <div className="text-h4 pt-[32px]">{t(s_materials.writing_focused_guide_text_1)}</div>
            <div className="text-h4 pt-[12px]">{t(s_materials.writing_focused_guide_text_2)}</div>
          </div>
        ) : data.course.id == SPEAKING_FOCUSED_FOR_INTERVIEW ? (
          <div>
            <div className="Hero3-36Bd mb-[32px]">
              {data.course.title_en ? data.course.title_en : data.course.title}
            </div>

            <div className="border-b pb-[32px] text-[16px] text-black" style={{ fontFamily: 'Georgia' }}>
              {isLocaleKO ? (
                <>
                  모의 인터뷰에 관한 주제로 수업을 할 수 있습니다.
                  <br />
                  준비한 자료를 수업에서 튜터와 함께 보고 목적에 맞는 수업을 진행해 보세요.
                  <br />
                  <br />
                  아래 모의 인터뷰 질문 목록도 참고해보세요.
                  <br />
                  <br />
                  {questionList}
                </>
              ) : (
                <>
                  Mock interview lessons offer students the opportunity to upload any relevant documents before the
                  lesson. During a Mock Interview lesson, tutors will ask and provide feedback on interview questions,
                  with reference to the uploaded material as necessary.
                  <br />
                  <br />
                  Questions asked by tutors may include, but are not limited to the following:
                  <br />
                  <br />
                  {questionList}
                </>
              )}
            </div>
            <div className="pt-[32px]">{t(s_materials.speaking_focused_guide_text1)}</div>
            <div className="pt-[12px]">{t(s_materials.speaking_focused_guide_text2)}</div>
          </div>
        ) : (
          <div>
            <div className="Hero3-36Bd mb-[32px]">
              {data.course.title_en ? data.course.title_en : data.course.title}
            </div>

            <div className="text-h2 border-b pb-[32px] text-black">{t(s_materials.speaking_focused_guide_title)}</div>
            <div className="pt-[32px]">{t(s_materials.speaking_focused_guide_text1)}</div>
            <div className="pt-[12px]">{t(s_materials.speaking_focused_guide_text2)}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default FreeTalkingInsightBody
