// @ts-strict-ignore
import { BUTTON_SIZE, BUTTON_TYPE, DSButton, DSModal } from '@repo/design-system/index'
import { tutor_apis } from '../../../../../modules/apis'
import { axiosDelete } from '../../../../../modules/axiosHelper'
import { COLORS } from '../../../../../modules/vars'
import { fetchCourseData } from '../LessonQuestionPanel'
import { useTranslatorSidePopup } from '../TranslatorSidePopup'
import { fetchData } from './LessonQuestionQuestionSelectPanel'

const LessonQuestionMyAnswerDeletePopupBody = ({
  showPopup,
  setShowPopup,
  answerId,
  setMyAnswers,
  isMaterial,
  lessonId,
  id,
  activeQuestionId,
}) => {
  const sidePopup = useTranslatorSidePopup()

  const handleOnCloseButtonClick = (e) => {
    e.stopPropagation(e)
    setShowPopup(false)
  }

  const handleOnApplyButtonClick = async (e) => {
    e.stopPropagation()

    const response = await axiosDelete(tutor_apis.lessons.preparation.patch({ answerId }))
    if (response) {
      setShowPopup(false)
      sidePopup.close('modify-popup')

      if (!isMaterial) {
        const newData: any = await fetchData(lessonId != undefined ? lessonId : id)

        if (newData) {
          setMyAnswers(
            newData.lesson?.preparation?.student_answers
              ? newData.lesson?.preparation?.student_answers.filter((item) => item.is_final)
              : []
          )
        }
      } else {
        const newData: any = await fetchCourseData(id)

        if (newData) {
          setMyAnswers(newData?.filter((item) => item.question_number == activeQuestionId)[0]?.answers?.my_answers)
        }
      }
    }
  }

  return (
    <DSModal id="TextOnlySMTwoButtonHorizontalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size={'lg'}>
      <DSModal.Title>
        <div className="flex flex-wrap text-[18px]">Do you wish to delete the memo?</div>
      </DSModal.Title>
      <DSModal.ButtonContainer direction="horizontal">
        <DSButton
          size={BUTTON_SIZE.MD}
          type={BUTTON_TYPE.OUTLINE}
          customBgColor={COLORS.WHITE}
          onClick={handleOnCloseButtonClick}
        >
          <DSButton.Label>Cancel</DSButton.Label>
        </DSButton>
        <DSButton size={BUTTON_SIZE.MD} onClick={handleOnApplyButtonClick}>
          <DSButton.Label>Delete</DSButton.Label>
        </DSButton>
      </DSModal.ButtonContainer>
    </DSModal>
  )
}

export default LessonQuestionMyAnswerDeletePopupBody
