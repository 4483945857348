import { TAB_TYPE, Tab } from '@repo/design-system/index'
import classNames from 'classnames'
import { useState } from 'react'
import { TEXT_SIZE, TextContext } from '../../../pages/[lang]/tutor/portal/lessons/lesson-materials/[materialId]'
import MaterialInsightBody from '../../material/MaterialInsightBody'
import { MediaPanel } from '../../modal/MaterialModal'
import MaterialDesktopBottomTab from './MaterialDesktopBottomTab'
import MaterialMobileBottomTab from './MaterialMobileBottomTab'
import QuestionPanel from './question/LessonQuestionPanel'

interface IMaterialBodyComponent {
  activeTabIdx: number
  materialData
  materialId: number
}

const NEW_TAB = {
  INSIGHT: 0,
  QUESTIONS: 1,
  MEDIA: 2,
}

const MaterialModalSubTab = ({ activeTabIdx, setActiveTabIdx, course }) => {
  const SUB_MENU_LIST = [
    {
      label: 'Insight',
      none: false,
    },
    {
      label: 'Questions',
      none: false,
    },
    {
      label: 'Video Clip / Article',
      none: course.video.length == 0,
    },
  ]

  return (
    <div className="border-b-1 z-[20] ml-[0px] w-screen bg-white sm:w-full">
      <div className="">
        <Tab
          className="h-[46px] overflow-hidden"
          type={TAB_TYPE.SCROLL}
          borderNone={true}
          activeTabIdx={activeTabIdx}
          setActiveTabIdx={setActiveTabIdx}
        >
          {SUB_MENU_LIST.filter((item) => !item.none).map((subMenuItem, i) => {
            return <Tab.TabItem name={subMenuItem.label} key={i} />
          })}
        </Tab>
      </div>
    </div>
  )
}
const MaterialBodyComponent = ({ activeTabIdx, materialData, materialId }: IMaterialBodyComponent) => {
  const media = {
    youtubes: materialData?.course.video,
    articles: materialData?.course.article,
  }

  switch (activeTabIdx) {
    case NEW_TAB.INSIGHT:
      return <MaterialInsightBody materialData={materialData} materialId={materialId} />
    case NEW_TAB.QUESTIONS:
      return (
        <div className={'pb-[60px]'}>
          <QuestionPanel id={materialId} materialData={materialData} />
        </div>
      )
    case NEW_TAB.MEDIA:
      return <MediaPanel media={media} />
    default:
      return <MaterialInsightBody materialData={materialData} materialId={materialId} />
  }
}

// 한글 같이 보기 버튼에 대한 페이지 전역 처리
export function TextProvider({ children }) {
  const [showKorean, setShowKorean] = useState<boolean>(false)
  const [textSize, setTextSize] = useState<string>(TEXT_SIZE.DEFAULT)
  return (
    <TextContext.Provider value={{ showKorean, setShowKorean, textSize, setTextSize }}>{children}</TextContext.Provider>
  )
}
const TutorMaterialDetailBody = ({ data, isWebview = false }) => {
  const [activeTabIdx, setActiveTabIdx] = useState<number>(0)

  return (
    <div className="relative">
      <div
        className={isWebview ? 'sticky top-[0px] z-10 h-[52px] bg-white' : 'sticky top-[70px] z-10 h-[52px] bg-white'}
      >
        <MaterialModalSubTab activeTabIdx={activeTabIdx} setActiveTabIdx={setActiveTabIdx} course={data?.course} />
      </div>
      <div className={classNames(isWebview && 'px-[20px]', 'relative mx-auto h-full w-full border-gray-300')}>
        <div className="relative mx-auto flex h-full w-full max-w-[790px] flex-col rounded-[4px]">
          <div className={isWebview ? 'mt-[20px]' : 'mt-[40px]'}>
            <MaterialBodyComponent activeTabIdx={activeTabIdx} materialData={data} materialId={data?.course?.id} />
          </div>
          {activeTabIdx === 0 && (
            <>
              <div className="block sm:hidden">
                <MaterialMobileBottomTab course={data?.course} />
              </div>
              <div className="z-[10] hidden flex-row sm:flex">
                <MaterialDesktopBottomTab course={data?.course} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TutorMaterialDetailBody
