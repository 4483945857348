import { PortableText } from '@portabletext/react'
import ImageUrlBuilder from '@sanity/image-url'
import Image from 'next/image'
import client from '../../modules/sanityClient'

export function updateMarksToBreak(data) {
  for (let i = 0; i < data?.length; i++) {
    const block = data[i]

    if (
      block._type === 'block' &&
      block.children &&
      block.children.length > 0 &&
      block.children[0]._type === 'span' &&
      block.children[0].marks.length === 0 &&
      block.children[0].text === ''
    ) {
      block.children[0].marks = ['breakLine']
    }
  }
  return data
}

export function convertToEmbedUrl(url) {
  // 'youtube.com' 또는 'youtu.be' 도메인이 있는지 확인합니다.
  if (url.includes('youtube.com') || url.includes('youtu.be')) {
    // 'youtube.com' 도메인에서 비디오 ID를 추출합니다.
    if (url.includes('youtube.com')) {
      const videoId = url.split('v=')[1]
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`
      }
    }
    // 'youtu.be' 도메인에서 비디오 ID를 추출합니다.
    else if (url.includes('youtu.be')) {
      const videoId = url.split('.be/')[1]
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`
      }
    }
  }

  // 변환할 수 없는 URL이면 원래 URL을 반환합니다.
  return url
}
const getExpressionMarks = () => {
  const marks = {}

  for (let i = 1; i <= 12; i++) {
    marks[`E${i}`] = (props) => {
      return (
        <a href={`#E${i}`} className="text-purple-400 underline">
          {props.children}
        </a>
      )
    }
  }

  return marks
}

export const expressionMarks = getExpressionMarks()

export const ptComponents = {
  block: {
    h2: (props) => {
      return <h2 className="Headline2-24Bd">{props.children}</h2>
    },
    h3: (props) => {
      return <h3 className="Headline3-22Bd">{props.children}</h3>
    },
    h4: (props) => {
      return <h4 className="Title1-20Bd">{props.children}</h4>
    },
    h5: (props) => {
      return <h5 className="Title2-18Bd">{props.children}</h5>
    },
    h6: (props) => {
      return <h6 className="Body1-16Md">{props.children}</h6>
    },
    bullet: (props) => {
      return <li className="">{props.children}</li>
    },
  },
  marks: {
    ...expressionMarks,
    color: (props) => {
      return <span style={{ color: props.value.hex }}>{props.children}</span>
    },
    link: (props) => {
      return (
        <span className="text-blue-500 underline">
          <a href={props.value.href} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        </span>
      )
    },
    purple: (props) => {
      return <span className="text-purple-500">{props.children}</span>
    },
    purple400: (props) => {
      return <span className="text-purple-400">{props.children}</span>
    },
    yellow: (props) => {
      return <span className="text-yellow-500">{props.children}</span>
    },
    green: (props) => {
      return <span className="text-green-500">{props.children}</span>
    },
    gray: (props) => {
      return <span className="text-gray-500">{props.children}</span>
    },
    red: (props) => {
      return <span className="text-red-500">{props.children}</span>
    },
    blue: (props) => {
      return <span className="text-blue-500">{props.children}</span>
    },
    blueHigh: (props) => {
      return <span className="bg-blue-100">{props.children}</span>
    },
    redHigh: (props) => {
      return <span className="bg-red-100">{props.children}</span>
    },
    greenHigh: (props) => {
      return <span className="bg-green-100">{props.children}</span>
    },
    purpleHigh: (props) => {
      return <span className="bg-purple-100">{props.children}</span>
    },
    yellowHigh: (props) => {
      return <span className="bg-yellow-100">{props.children}</span>
    },
    span: (props) => {
      return <span>{props.children}</span>
    },
    breakLine: (props) => {
      return <br />
    },
    right: (props) => {
      return <div className="flex w-full justify-end">{props.children}</div>
    },
    left: (props) => {
      return <div className="justify-left flex w-full">{props.children}</div>
    },
    center: (props) => {
      return <div className="flex w-full justify-center">{props.children}</div>
    },
    strong: (props) => {
      return <span className="font-bold">{props.children}</span>
    },
  },
  types: {
    image: ({ value }: any) => {
      if (!value?.asset?._ref) {
        return null
      }
      const imageUrl = (width, height) => {
        if (width && height) {
          return urlFor(value).width(width).height(height).url()
        } else if (width && !height) {
          return urlFor(value).width(width).url()
        } else if (!width && height) {
          return urlFor(value).height(height).url()
        } else {
          return urlFor(value).fit('max').url()
        }
      }
      return (
        <>
          {value?.width && value?.height ? (
            <Image
              alt={value?.alt || ' '}
              loading="lazy"
              src={imageUrl(value?.width, value?.height) as any}
              width={value?.width}
              height={value?.height}
              style={{ borderRadius: `${value?.imageRadius || 0}px` }}
            />
          ) : (
            <Image
              alt={value?.alt || ' '}
              loading="lazy"
              src={imageUrl(value?.width, value?.height) as any}
              width={800}
              height={800}
              style={{ borderRadius: `${value?.imageRadius || 0}px` }}
            />
          )}
        </>
      )
    },
    blockQuote: ({ value }: any) => {
      const content = updateMarksToBreak(value.content || [])

      return (
        <div className="flex w-full flex-col rounded-[8px] bg-gray-100 p-[20px]">
          <div className="Title2-18Bd mb-[32px]">{value.title}</div>
          <div className="Body1-16Md">
            <PortableText value={content} components={ptComponents} />
          </div>
        </div>
      )
    },
    breakLine: ({ value }: any) => {
      return <hr className="my-[20px]" />
    },
    youtube: ({ value }: any) => {
      return (
        <div className="aspect-[16/9]">
          <iframe
            width="100%"
            height="100%"
            className="aspect-[16/9]"
            src={convertToEmbedUrl(value.link)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )
    },
  },
}

export function urlFor(source: string) {
  const builder = ImageUrlBuilder(client)
  return builder.image(source)
}
